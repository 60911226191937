import { DataFieldWithDataType } from '../../../common-types';
import {
  MetricCategoryId,
  MetricCategoryTree,
  MetricGroupId,
  MetricId,
  MetricTree,
  MetricType,
} from '../graphql/generated/graphql-sdk';
import { DomainDependencyStore } from '../startup/domain-dependency-store';
import { enumValues } from '../utilFunctions/pure-utils';
import { MetricGroupDetails, MetricLifecycleStageEnum } from './types';

export class MetricService {
  private domainDependencyStore: DomainDependencyStore;

  private metricIdsSet = new Set(enumValues(MetricId));
  private metricGroupsSet = new Set(enumValues(MetricGroupId));
  private metricCategoriesSet = new Set(enumValues(MetricCategoryId));

  constructor(domainDependencyStore: DomainDependencyStore) {
    this.domainDependencyStore = domainDependencyStore;
  }

  private cleanupMetricTree = (metricTreeFromBackend: MetricTree): MetricTree => {
    // This function will remove any items that don't have ids in graphql-sdk.ts
    // This will happen if there is new data on the backend. Since frontend might not
    // support that fully(in translation, data validation in zod, etc), we just remove them from
    // the fetched list
    const cleanedMetricTree: MetricTree = {
      ...metricTreeFromBackend,
      categoryTrees: metricTreeFromBackend.categoryTrees
        .map((cTree) => {
          if (!this.metricCategoriesSet.has(cTree.metricCategoryId)) {
            return null;
          } else {
            return {
              ...cTree,
              groupTrees: cTree.groupTrees
                .map((gTree) => {
                  if (!this.metricGroupsSet.has(gTree.metricGroupId)) {
                    return null;
                  } else {
                    return {
                      ...gTree,
                      metricDefs: gTree.metricDefs
                        .map((mDef) => {
                          if (!this.metricIdsSet.has(mDef.id)) {
                            return null;
                          } else {
                            return mDef;
                          }
                        })
                        .filter(Boolean),
                    };
                  }
                })
                .filter(Boolean),
            };
          }
        })
        .filter(Boolean) as MetricCategoryTree[],
    };
    return cleanedMetricTree;
  };

  private convertMetricTreeToMetricDetails = (metricTree: MetricTree): MetricGroupDetails[] => {
    const metricGroupDetails = metricTree.categoryTrees
      .flatMap((catTree) => {
        const { metricCategoryId, groupTrees, metricCategory } = catTree;
        const metricsWithDimensions: MetricGroupDetails[] = groupTrees.map((group) => {
          const { metricGroupId, metricDefs } = group;
          const allowedLifeCycleStages = [
            MetricLifecycleStageEnum.MetricLifecycleStageActive,
            MetricLifecycleStageEnum.MetricLifecycleStageDeprecated,
          ];
          const activeMetricDefs = metricDefs.filter((d) =>
            allowedLifeCycleStages.includes(d.lifecycleStage.__typename as MetricLifecycleStageEnum)
          );
          const metricDetails = activeMetricDefs.map((def) => {
            return {
              id: def.id,
              metricGroupId: def.metricGroupId,
              formatType: def.formatType.id,
              underlyingFields: def.underlyingDataFields as unknown as DataFieldWithDataType[],
              isCohort: def.metricType === MetricType.MetricTypeCohort,
              defaultPosition: def.defaultPosition,
            };
          });
          return {
            id: metricGroupId,
            defaultCategory: metricCategoryId,
            dimensions: metricDetails,
            defaultPosition: group.metricGroup.defaultPosition,
            defaultCategoryPosition: metricCategory.defaultPosition,
          };
        });
        return metricsWithDimensions;
      })
      .filter((g) => g.dimensions.length);
    return metricGroupDetails;
  };

  public getMetricTree = (): MetricGroupDetails[] => {
    const metricTree = this.domainDependencyStore.getMetricTree();
    if (!metricTree) {
      throw new Error('null metric tree returned');
    }
    const cleanedMetricTree = this.cleanupMetricTree(metricTree);
    return this.convertMetricTreeToMetricDetails(cleanedMetricTree);
  };
}
