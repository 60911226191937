import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Tooltip } from '@mui/material';
import { closeSnackbar, SnackbarAction } from 'notistack';
import React from 'react';
import { materialUiDefaultBlack } from '../../theme/default-theme';
export const NotistackSnackbarCloseAction: SnackbarAction = (snackbarId) => {
  return (
    <Tooltip title="Close">
      <IconButton sx={{ color: materialUiDefaultBlack }} onClick={() => closeSnackbar(snackbarId)}>
        <CloseIcon />
      </IconButton>
    </Tooltip>
  );
};
