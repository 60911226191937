import memoize from 'fast-memoize';
import isEqual from 'lodash.isequal';
import { Moment } from 'moment';
import { DataFieldWithDataType, toDataFieldWithDataType } from '../../../common-types';
import {
  $HierarchicalFilterSeparator$,
  ApiGetManagerResponse,
  ApiGetManagerResponseSubordinateItem,
  ApiHierarchyItem,
  ApiMasterDataQueryFilterItem,
  ApiMasterDataTypes,
} from '../api/api-interfaces';
import { FilterTrayItemProps } from '../components/filter-tray/FilterTrayItem';
import { DataFields, DataTypes, EmployeeDataFields, Operations } from '../constants/constants';
import { Granularity, Months } from '../date-manager/date-manager-constants';
import { DateManagerService, dateManagerService } from '../date-manager/date-manager-service';
import {
  getEndOfFiscalYear,
  getEndOfQuarter,
  getStartOfFiscalYear,
  getStartOfQuarter,
} from '../date-manager/date-manager-utils';
import { trackFilterApply } from '../helpers/trackers/filterTracker';
import { trackSegment } from '../helpers/trackers/sidebarTracker';
import { rootStore } from '../store/root-store';
import { chartColors, genderColorMap } from '../theme/default-theme';
import { formatEmpIdToNameAndTitle } from '../utilFunctions/formatters';
import { hasUndefined, isHierarchical } from '../utilFunctions/utils';
import { FilterValue, HierarchicalFilterValue, PeriodRange, TimeSliderConfig } from './filter-store';

export const getEndOfMonth = (date: Moment) => {
  return dateManagerService.formatDateApi(date.clone().endOf(Granularity.MONTH));
};

export const getStartOfMonth = (date: Moment) => {
  return dateManagerService.formatDateApi(date.clone().startOf(Granularity.MONTH));
};

const combineFiltersConstructor = (filters: any) => {
  const filtersCopy = JSON.parse(JSON.stringify(filters || []));
  const filterItems: ApiMasterDataQueryFilterItem[] = [];
  filtersCopy.forEach((filter: any) => {
    const foundFilter = filterItems.find(
      (item) =>
        item.property === filter.property &&
        item.operation === filter.operation &&
        item.dataType === filter.dataType &&
        item.operation !== Operations.NOT_EQUAL &&
        filter.operation !== Operations.NOT_EQUAL &&
        item.dontCombine !== true &&
        filter.dontCombine !== true
      // for not equal, or doesn't make sense
      // (x != y || x != z) will always be true if y and z are different
    );

    if (foundFilter) {
      foundFilter.values = Array.from(new Set([...foundFilter.values, ...filter.values]));
    } else {
      filterItems.push(filter);
    }
  });
  return filterItems;
};

export const combineFilters = memoize(combineFiltersConstructor);

export const getVersionFilterFromDate = (
  date: Moment,
  firstMonthOfYear: Months,
  dataType: ApiMasterDataTypes | DataTypes = 'EMPLOYEE'
): ApiMasterDataQueryFilterItem => {
  const version = getApplicableVersionForDate(
    date,
    firstMonthOfYear,
    undefined,
    rootStore.domainDependencyStore.getLatestVersion(dataType as DataTypes) as string
  );
  return {
    operation: Operations.EQUAL,
    property: 'VERSION_ID',
    values: [version],
    dataType: dataType as ApiMasterDataTypes,
  };
};

export const getVersionFilterFromDateRange = (
  dates: string[],
  dataType: ApiMasterDataTypes | DataTypes = 'EMPLOYEE'
): ApiMasterDataQueryFilterItem => {
  return {
    operation: Operations.EQUAL,
    property: 'VERSION_ID',
    values: dates,
    dataType: dataType as ApiMasterDataTypes,
  };
};

export const endOfDate = (date: Moment, unitOfTime: Granularity, firstMonthOfYear: Months) => {
  const dateCopy = date.clone();
  if (unitOfTime === Granularity.FINQUARTER) {
    return getEndOfQuarter(dateCopy, firstMonthOfYear);
  } else if (unitOfTime === Granularity.FINYEAR) {
    return getEndOfFiscalYear(dateCopy, firstMonthOfYear);
  }
  return dateCopy.endOf(unitOfTime);
};

export const isSameDate = (
  firstDate: Moment,
  secondDate: Moment,
  granularity: Granularity,
  firstMonthOfYear: Months
): boolean => {
  if (granularity === Granularity.FINYEAR) {
    const endOfFinYear1 = endOfDate(firstDate, granularity, firstMonthOfYear);
    const endOfFinYear2 = endOfDate(secondDate, granularity, firstMonthOfYear);
    return isSameDate(endOfFinYear1, endOfFinYear2, Granularity.MONTH, firstMonthOfYear);
  }
  return firstDate.isSame(secondDate, getRawBenchmarkGranularity(granularity));
};

export const startOfDate = (date: Moment, unitOfTime: Granularity, firstMonthOfYear: Months) => {
  const dateCopy = date.clone();
  if (unitOfTime === Granularity.FINQUARTER) {
    return getStartOfQuarter(date, firstMonthOfYear);
  } else if (unitOfTime === Granularity.FINYEAR) {
    return getStartOfFiscalYear(date, firstMonthOfYear);
  }
  return dateCopy.startOf(unitOfTime);
};

const addUnitGranularityToDate = (date: Moment, granularity: Granularity) => {
  if (granularity === Granularity.FINYEAR) {
    return date.add(12, Granularity.MONTH).endOf(Granularity.MONTH);
  }
  return date.add(1, getRawBenchmarkGranularity(granularity));
};

export const getDatesInRange = (
  timeSliderConfig: TimeSliderConfig,
  providedDateManagerService: DateManagerService = dateManagerService
) => {
  const { startDate, endDate, granularity = Granularity.MONTH, firstMonthOfYear } = timeSliderConfig;
  if (!startDate || !endDate) {
    throw new Error('Undefined dates found in getDatesInRange function');
  }
  const results = [];
  const { parseApiDate, formatDateApi } = providedDateManagerService;
  const minStartDate = parseApiDate(startDate);
  const maxEndDate = parseApiDate(endDate);
  let date = minStartDate.clone();
  while (endOfDate(date, granularity, firstMonthOfYear).isSameOrBefore(maxEndDate, Granularity.DAY)) {
    date = endOfDate(date, granularity, firstMonthOfYear);
    results.push(formatDateApi(date));
    date = addUnitGranularityToDate(date, granularity);
  }
  if (maxEndDate.clone().isBefore(endOfDate(maxEndDate, granularity, firstMonthOfYear), Granularity.DAY)) {
    results.push(formatDateApi(maxEndDate));
  }
  return results;
};

export const getMonthlyVersionsInLatestGranularityPeriod = (timeSliderConfig: TimeSliderConfig) => {
  // eg - for quarter granularity, get all monthly versions in latest quarter
  // useful for cases such as payroll where we need total value in quarter or year
  const { endDate, granularity, firstMonthOfYear } = timeSliderConfig;
  const { formatDateApi, parseApiDate } = dateManagerService;
  const startOfPeriod = formatDateApi(startOfDate(parseApiDate(endDate), granularity, firstMonthOfYear));
  const monthlyVersionsInPeriod = getDatesInRange({
    ...timeSliderConfig,
    startDate: startOfPeriod,
    granularity: Granularity.MONTH,
  });
  return monthlyVersionsInPeriod;
};

export const getMonthStartAndMonthEndDatesInPeriod = (startDate: Moment, endDate: Moment, firstMonthOfYear: Months) => {
  // This function should always return month start and month end dates and therefore doesn't need granularity
  const dates = [];
  let tempStartDate = startDate;
  const tempEndDate = endDate;
  const { formatDateApi } = dateManagerService;
  while (tempStartDate.isBefore(tempEndDate, Granularity.MONTH)) {
    dates.push(
      formatDateApi(tempStartDate),
      formatDateApi(endOfDate(tempStartDate, Granularity.MONTH, firstMonthOfYear))
    );
    tempStartDate = startOfDate(tempStartDate.add(1, Granularity.MONTH), Granularity.MONTH, firstMonthOfYear);
  }
  dates.push(formatDateApi(tempStartDate), formatDateApi(tempEndDate));
  return dates;
};

//TODO: move this to domainDependencyStore?
export const getApplicableVersionForDate = (
  date: Moment,
  firstMonthOfYear: Months,
  granularity: Granularity = Granularity.MONTH,
  latestVersion: string = rootStore.domainDependencyStore.getLatestVersion(DataTypes.EMPLOYEE) as string
) => {
  const endDate = endOfDate(date, granularity, firstMonthOfYear);
  return endDate.isAfter(dateManagerService.parseApiDate(latestVersion))
    ? latestVersion
    : dateManagerService.formatDateApi(endDate);
};

export const getPastDatesFromDate = (
  toDate: Moment,
  amount: number,
  unit: Granularity.MONTH | Granularity.YEAR | Granularity.DAY
) => {
  return toDate.subtract(amount, unit);
};

export const getChartColorsForByDimChart = (dimension: DataFieldWithDataType, chartType: string, labels: string[]) => {
  let colors;
  labels = labels.map((l) => l || 'NA');
  if (chartType === 'doughnut') {
    if (dimension.dataField === 'GENDER') {
      colors = labels.map((label: string) => genderColorMap[label]);
    } else {
      colors = labels.map((_l: any, i: number) => {
        return chartColors[i % 13];
      });
    }
  } else {
    colors = chartColors[0];
  }
  return colors;
};

export const getChartColorsForIndex = (index: number) => {
  return chartColors[index % chartColors.length];
};

// filter is new chosen filter, filters is all selected filters. Toggle the filter in or out.
export const toggleFilterFromGivenFilters = (
  filter: ApiMasterDataQueryFilterItem,
  filters: ApiMasterDataQueryFilterItem[],
  segmentLength?: number
) => {
  if (!hasUndefined(filter)) {
    const index = filters.findIndex((f) => isEqual(f, filter));
    if (index !== -1) {
      filters.splice(index, 1);
    } else {
      const { MAX_ALLOWED_SEGMENTS } = rootStore.segmentationStore;
      if (segmentLength) {
        if (segmentLength >= MAX_ALLOWED_SEGMENTS) {
          return;
        }
        trackSegment(filter);
      } else {
        trackFilterApply(filter);
      }
      filters.push(filter);
    }
  }
};

export const getQuarterMonthsUptilDate = (date: Moment) => {
  const monthNumInItsQuarter = getMonthNumInItsQuarter(date);
  let months: string[] = [dateManagerService.formatDateApi(date)];
  for (let i = 1; i < monthNumInItsQuarter; i++) {
    const previousMonthDate = dateManagerService.formatDateApi(
      date.clone().subtract(i, Granularity.MONTH).endOf(Granularity.MONTH)
    );
    months = [previousMonthDate, ...months];
  }
  return months;
};

const getMonthNumInItsQuarter = (date: Moment) => {
  const monthNum = date.month() + 1;
  let monthNumInItsQuarter;
  if (monthNum % 3 === 0) {
    monthNumInItsQuarter = 3;
  } else if (monthNum % 3 === 1) {
    monthNumInItsQuarter = 1;
  } else {
    monthNumInItsQuarter = 2;
  }
  return monthNumInItsQuarter;
};

export const getSamePrecedingPeriodTimeSliderConfig = (currTimeSliderConfig: TimeSliderConfig) => {
  const { startDate: currStartDate, endDate: currEndDate } = currTimeSliderConfig;
  const { parseApiDate, formatDateApi } = dateManagerService;
  // assumes month granularity
  const numMonthsInPeriod = parseApiDate(currEndDate).diff(currStartDate, Granularity.MONTH) + 1;
  const prevStartDate = formatDateApi(
    parseApiDate(currStartDate).subtract(numMonthsInPeriod, Granularity.MONTH).startOf(Granularity.MONTH)
  );
  const prevEndDate = formatDateApi(
    parseApiDate(currEndDate).subtract(numMonthsInPeriod, Granularity.MONTH).endOf(Granularity.MONTH)
  );
  return {
    ...currTimeSliderConfig,
    startDate: prevStartDate,
    endDate: prevEndDate,
  };
};

export const getSpecificPeriodTimeSliderConfig = (
  specificPeriodRange: PeriodRange,
  timeSliderConfig: TimeSliderConfig
) => {
  const { startDate, endDate } = specificPeriodRange;
  return {
    ...timeSliderConfig,
    startDate,
    endDate,
  };
};

export const getRawBenchmarkGranularity = (granularity: Granularity) => {
  switch (granularity) {
    case Granularity.FINYEAR:
      return Granularity.YEAR;
    case Granularity.FINQUARTER:
      return Granularity.QUARTER;
    case Granularity.DAY:
    case Granularity.MONTH:
    case Granularity.WEEK:
    case Granularity.YEAR:
      return granularity;
  }
};
export const getTimeSliderConfigForQtAndYearBenchmark = (
  timeSliderConfig: TimeSliderConfig,
  benchmarkGranularity: Granularity
) => {
  const { startDate, endDate, granularity, firstMonthOfYear } = timeSliderConfig;
  const rawBenchmarkGranularity = getRawBenchmarkGranularity(benchmarkGranularity);
  const { formatDateApi, parseApiDate } = dateManagerService;
  const benchmarkStartDate = formatDateApi(
    startOfDate(parseApiDate(startDate).subtract(1, rawBenchmarkGranularity), granularity, firstMonthOfYear)
  );
  // ^ Here I can probably get away with not doing start of year but I guess its safer to do it anyway
  // This assumes that the start date provided will always be start of granularity

  // Have to confirm how this handles things like leap year etc
  const benchmarkEndDate = formatDateApi(
    endOfDate(parseApiDate(endDate).subtract(1, rawBenchmarkGranularity), granularity, firstMonthOfYear)
  );
  // ^ Need this as current month might not be completed. Not sure what issues this could cause in the
  // future when we have weekly granualrity etc. Maybe not many
  const benchmarkTimeSliderConfig = {
    ...timeSliderConfig,
    startDate: benchmarkStartDate,
    endDate: benchmarkEndDate,
  };
  return benchmarkTimeSliderConfig;
};

export const twelveMonthsBeforeDate = (date: Moment) => {
  const { formatDateApi } = dateManagerService;
  return formatDateApi(date.subtract(11, Granularity.MONTH).startOf(Granularity.MONTH));
};

export const areSameNonHierarchicalFilters = (
  filter1: ApiMasterDataQueryFilterItem,
  filter2: ApiMasterDataQueryFilterItem
) => {
  return (
    filter1.property === filter2.property &&
    filter1.operation === filter2.operation &&
    filter1.dataType === filter2.dataType &&
    filter1.values[0] === filter2.values[0]
  );
};

const areSameFilterValues = (filterFromTray: FilterValue, filterFromStore: FilterValue, isHierarchical: boolean) => {
  return isHierarchical
    ? (filterFromTray as HierarchicalFilterValue).join() === (filterFromStore as HierarchicalFilterValue).join()
    : filterFromTray === filterFromStore;
};

export const areSameFilters = (filter1: ApiMasterDataQueryFilterItem, filter2: ApiMasterDataQueryFilterItem) => {
  const isHierarchicalFilter =
    isHierarchical(toDataFieldWithDataType(filter1.dataType, filter1.property)) &&
    isHierarchical(toDataFieldWithDataType(filter2.dataType, filter2.property));

  const isSame =
    filter1.property === filter2.property &&
    filter1.operation === filter2.operation &&
    filter1.dataType === filter2.dataType &&
    areSameFilterValues(filter1.values[0], filter2.values[0], isHierarchicalFilter);

  return isSame;
};

export const getPeriodStartDateFromStartDate = (
  startDate: Moment,
  granularity: Granularity = Granularity.MONTH,
  firstMonthOfYear: Months
) => dateManagerService.formatDateApi(startOfDate(startDate, granularity, firstMonthOfYear));

export const convertFilterItemsToJoinersViewDataType = (
  filters: ApiMasterDataQueryFilterItem[]
): ApiMasterDataQueryFilterItem[] => {
  return filters.map((f) => ({
    ...f,
    dataType: f.dataType === DataTypes.EMPLOYEE ? DataTypes.JOINERS_VIEW : f.dataType,
  }));
};

export const arrangeIntoTree = (reportingLine: ApiGetManagerResponse) => {
  const findWhere = (array: ApiHierarchyItem[], key: keyof ApiHierarchyItem, value: string) => {
    // Adapted from https://stackoverflow.com/questions/32932994/findwhere-from-underscorejs-to-jquery
    let t: number = 0; // t is used as a counter
    while (t < array.length && array[t][key] !== value) {
      t++;
    } // find the index where the id is the as the aValue

    if (t < array.length) {
      return array[t];
    } else {
      return null;
    }
  };

  let roots: FilterTrayItemProps[] = [];

  reportingLine.items.forEach((hierarchy) => {
    // Adapted from http://brandonclapp.com/arranging-an-array-of-flat-paths-into-a-json-tree-like-structure/
    const tree: FilterTrayItemProps[] = [];
    hierarchy.subordinates?.forEach((subordinate) => {
      const managerIds: string[] = subordinate.path.split('.');
      let currentLevel: ApiHierarchyItem[] = tree;
      managerIds.forEach((managerId: string) => {
        const existingPath: ApiHierarchyItem | null = findWhere(currentLevel, 'name', managerId);

        if (existingPath) {
          currentLevel = existingPath.subItems ?? [];
        } else {
          const sub: ApiGetManagerResponseSubordinateItem | undefined = hierarchy.subordinates?.find(
            (s) => s.id === managerId
          );
          if (sub) {
            const displayName = formatEmpIdToNameAndTitle(sub.id);
            const newPart: FilterTrayItemProps = {
              dimension: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.MANAGER_ID },
              name: sub.id,
              displayName: displayName ?? sub.id,
              noDefaultSubItems: false,
              isExpandable: !!sub.subordinatesCount,
              level: sub.hierarchyLevel,
              isHierarchical: !!sub.subordinatesCount,
              subItems: [],
            };
            currentLevel.push(newPart);
            currentLevel = newPart.subItems ?? [];
          }
        }
      });
    });
    const rootEmpDisplayName = formatEmpIdToNameAndTitle(hierarchy.id);
    roots.push({
      dimension: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.MANAGER_ID },
      name: hierarchy.id,
      displayName: rootEmpDisplayName ?? hierarchy.id,
      noDefaultSubItems: false,
      isExpandable: true,
      level: 1,
      isHierarchical: true,
      subItems: tree,
    });
  });
  return roots;
};

export const findNonHierarchicalItemInHierarchy = (
  value: string,
  filterHierarchy: ApiHierarchyItem[]
): ApiHierarchyItem | undefined => {
  const foundItem: ApiHierarchyItem | undefined = filterHierarchy.find((h) => {
    if (h.name === value) {
      return h;
    } else if (h.subItems?.length) {
      return findNonHierarchicalItemInHierarchy(value, h.subItems);
    } else {
      return undefined;
    }
  });
  return foundItem;
};

export const findHierarchicalItemInHierarchy = (
  filterValues: string[],
  filterHierarchy: ApiHierarchyItem[]
): ApiHierarchyItem | undefined => {
  let subTree = filterHierarchy;
  filterValues.front().forEach((filter) => {
    subTree = subTree?.find((tree) => tree.name === filter)?.subItems ?? [];
  });
  const foundItem = subTree.find((tree) => tree.name === filterValues.last());
  return foundItem;
};

export const getChildren = (filterString: string, filterHierarchy: ApiHierarchyItem[]): ApiHierarchyItem[] => {
  const filterValues = filterString.split($HierarchicalFilterSeparator$);
  const subTree = findHierarchicalItemInHierarchy(filterValues, filterHierarchy)?.subItems ?? [];
  return subTree;
};

export const getDimension = (dataType: DataTypes, dataField: DataFields): DataFieldWithDataType => {
  return {
    dataType,
    dataField,
  };
};

export const getEmployeeDimension = (dataField: EmployeeDataFields) => getDimension(DataTypes.EMPLOYEE, dataField);
