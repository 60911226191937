import { queryClient } from '../../app';
import { ApiMasterDataQueryFilterItem } from '../api/api-interfaces';
import { Granularity } from '../date-manager/date-manager-constants';
import { TimeSliderConfig } from '../filter/filter-store';
import { ServiceInputs } from './ByDimensionService';
import { DataMapAndLabelsWithForecast } from './getForecastResult';

export interface Service<Inputs, Response> {
  (inputs: Inputs, filters: ApiMasterDataQueryFilterItem[]): Promise<Response>;
  cacheKey: string;
}

export const getServiceCacheKey = (service: Service<any, any>, inputs: any, filters: ApiMasterDataQueryFilterItem[]) =>
  JSON.stringify([inputs, filters, service.toString(), service.cacheKey]);

interface OverTimeChartDatamapsAndLabels {
  primaryDataMap: Record<string, any>;
  primaryDates: string[];
  benchmarkDataMap: Record<string, any> | null;
  benchmarkDates: string[];
  forecastDataMap?: DataMap;
}

export type DataMap<DataShape = any> = Record<string, DataShape>;
export interface DataMapAndLabels<DataShape = any> {
  labels: string[];
  dataMap: DataMap<DataShape>;
}

interface OvertimeServiceResponseDataObj extends DataMapAndLabelsWithForecast {
  inputs: { timeSliderConfig: TimeSliderConfig; [key: string]: any };
  // TODO: Ideally this should be set up as a generic which accepts inputProps
  // But it is too much work and refactor to do this correctly right now
}

export interface OvertimeServiceResponseData {
  primaryResponse: OvertimeServiceResponseDataObj;
  benchmarkResponse?: OvertimeServiceResponseDataObj;
  granularity: Granularity;
}

export const getDataMapsAndLabelsForOverTimeChart = (
  data: OvertimeServiceResponseData
): OverTimeChartDatamapsAndLabels => {
  const { primaryResponse, benchmarkResponse } = data;
  let { dataMap: primaryDataMap, labels: primaryDates, forecastDataMap } = primaryResponse;
  let benchmarkDataMap: Record<string, any> | null = null;
  let benchmarkDates: string[] = [];
  if (benchmarkResponse) {
    benchmarkDataMap = benchmarkResponse.dataMap;
    benchmarkDates = benchmarkResponse.labels;
  }
  return { primaryDates, primaryDataMap, benchmarkDates, benchmarkDataMap, forecastDataMap };
};

export const getBenchmarkWrapperServiceInputs = <Inputs, Response>(service: Service<Inputs, Response>) => ({
  service,
  serviceCacheKey: service.cacheKey,
});

export const getReactQueryWrappedService = <Inputs, Response>(
  service: Service<Inputs, Response>
): Service<Inputs, Response> => {
  const reactQueryWrappedService: Service<Inputs, Response> = async (inputs, filters) => {
    return queryClient.fetchQuery({
      queryKey: [inputs, filters, service.cacheKey],
      queryFn: () => service(inputs, filters),
    });
  };
  reactQueryWrappedService.cacheKey = service.cacheKey;
  return reactQueryWrappedService;
};

export const runServiceForDates = async (
  sDate: string,
  eDate: string,
  serviceInputs: ServiceInputs,
  timeSliderConfig: TimeSliderConfig
) => {
  return serviceInputs.service(
    {
      ...serviceInputs.inputs,
      timeSliderConfig: {
        ...timeSliderConfig,
        startDate: sDate,
        endDate: eDate,
      },
      date: eDate,
      forecastConfig: undefined,
    },
    serviceInputs.filters
  );
};
