import { action, computed, observable } from 'mobx';
import { ApiDashboard, FAVORITE_DASHBOARDS_KEY } from './api/api-interfaces';
import { DashboardApiService } from './api/dashboard-service';
import { CompanyStore } from './company/company-store';
import { Dashboards, DataTypes, Domains } from './constants/constants';
import { localStore } from './local-store';
import { IPermissionsStore } from './permissions/permissions-store';

export class DashboardStore {
  private companyStore: CompanyStore;
  private permissionsStore: IPermissionsStore;
  private dashboardApiService: DashboardApiService;

  constructor(
    companyStore: CompanyStore,
    permissionsStore: IPermissionsStore,
    dashboardApiService: DashboardApiService
  ) {
    this.companyStore = companyStore;
    this.permissionsStore = permissionsStore;
    this.dashboardApiService = dashboardApiService;
  }

  @computed
  private get dashboardToLatestVersionDataTypeMap(): Partial<Record<Dashboards, DataTypes>> {
    return {
      [Dashboards.EVALUATION]: DataTypes.EVALUATION,
      [Dashboards.PAYROLL]: DataTypes.PAYROLL,
      [Dashboards.WORK_HOURS_AND_OT]: DataTypes.TIMEANDATTENDANCEMONTHLY,
      [Dashboards.RECRUITMENT_OVERVIEW]: DataTypes.APPLICATION,
      [Dashboards.SURVEY]: DataTypes.QUESTIONANSWER,
    };
  }

  public getLatestVersionDataTypeForDashboard = (dashboard: Dashboards) =>
    this.dashboardToLatestVersionDataTypeMap[dashboard] ?? DataTypes.EMPLOYEE;

  public getDashboard(id: Dashboards): ApiDashboard | undefined {
    return this.allowedDashboards.find((db) => db.id === id);
  }

  @computed
  public get allowedDashboards(): ApiDashboard[] {
    const { selectedCompany } = this.companyStore;
    const companyAllowedDashboards = selectedCompany?.allowedDashboards ?? [];
    if (!companyAllowedDashboards.length) {
      return [];
    }

    let allowedDashboards = this.permissionsStore.getAllowedDashboardsForUser(
      this.dashboards,
      companyAllowedDashboards
    );

    const favoriteDashboardsRating = this.getFavoriteDashboards();

    if (Object.keys(favoriteDashboardsRating).length) {
      const favoriteAllowedDashboards = allowedDashboards
        .filter((db) => favoriteDashboardsRating[db.id])
        .sort((a, b) => favoriteDashboardsRating[b.id] - favoriteDashboardsRating[a.id]);

      allowedDashboards = Array.from(new Set([...favoriteAllowedDashboards, ...allowedDashboards]));
    }

    return allowedDashboards;
  }

  @observable
  public dashboards: ApiDashboard[] = [];

  @observable
  public dashboardsFetched = false;

  @observable
  public isFullScreen: boolean = false;

  @action
  public setFullScreen = (isFullScreen: boolean) => {
    this.isFullScreen = isFullScreen;
  };

  public toggleFullScreen = () => {
    this.isFullScreen = !this.isFullScreen;
  };

  @action
  public async getAllDashboards() {
    const dashboards = await this.dashboardApiService.listDashboards();
    this.dashboards = dashboards.dashboards;
    this.dashboardsFetched = true;
    // TODO: Remove hacky code.
    if (![Domains.LOOOP, Domains.SWAN, Domains.SHIRATORI].includes(this.companyStore.domain as Domains)) {
      const surveyDb = this.dashboards.find((db) => db.id === Dashboards.SURVEY);
      if (surveyDb) {
        surveyDb.pages = surveyDb.pages.filter((p) => p.pageId !== 'survey-questions');
      }
    }
    return dashboards;
  }

  public getFavoriteDashboards() {
    return JSON.parse(localStore.get(FAVORITE_DASHBOARDS_KEY) ?? JSON.stringify({}));
  }

  public addDashboardToFavoriteDashboards(dashboard: Dashboards) {
    const favoriteDashboards = this.getFavoriteDashboards();
    localStore.set(
      FAVORITE_DASHBOARDS_KEY,
      JSON.stringify({
        ...favoriteDashboards,
        [dashboard]: (favoriteDashboards[dashboard] ?? 0) + 1,
      })
    );
  }
}
