import { action, autorun, computed, observable, runInAction } from 'mobx';
import {
  DataFieldWithDataType,
  compareFilterToDim,
  toAdvancedDimension,
  toDataFieldWithDataType,
} from '../../../common-types';
import i18n from '../../../i18n';
import { cachedMasterDataApiService } from '../../common/api/master-data-service';
import { applicationToOfferTableJoin } from '../../pages/dashboard/recruitment/services/utils';
import {
  AllDisplayString,
  ApiHierarchyItem,
  ApiMasterDataQuery,
  ApiMasterDataQueryFilterItem,
  toApiMasterDataAdvancedQueryWithTypeInfo,
} from '../api/api-interfaces';
import { CompanyStore } from '../company/company-store';
import { TabConfig, TabConfigType } from '../components/dashboard/dashboard-data-view';
import {
  ApplicationDataFields,
  DataTypes,
  EmploymentTemporality,
  InterviewDataFields,
  JobDataFields,
  OfferDataFields,
  Operations,
  RecruiterJobsDataFields,
} from '../constants/constants';
import { getVersionFilter, getVersionFilterItems } from '../filter/common-filters';
import { FilterStore } from '../filter/filter-store';
import { combineFilters } from '../filter/utils';
import { localStore } from '../local-store';
import { FrontendMetricGroupId } from '../metrics/backendMetricMappings';
import { GetUserVisibleNonConfidentialNonNullFieldsForDataTypeService } from '../services/GetNonNullFieldsForDataTypeService';
import { DomainDependencyStore } from '../startup/domain-dependency-store';
import { TimeSliderStore } from '../timeslider/timeslider-store';
import { Ordering } from '../utilFunctions/comparators';
import { hasUndefined } from '../utilFunctions/utils';
import { ApiMasterDataAdvancedQuery } from './../api/api-interfaces';
import { Ord } from './../utilFunctions/comparators';

export enum RecruitmentStages {
  APPLICATIONS_AT_APPLIED_STAGE = 'APPLICATIONS_AT_APPLIED_STAGE',
  APPLICATIONS_AT_APPLIED_STAGE_PASSED = 'APPLICATIONS_AT_APPLIED_STAGE_PASSED',
  APPLICATIONS_AT_APPLIED_STAGE_REJECTED = 'APPLICATIONS_AT_APPLIED_STAGE_REJECTED',
  APPLICATIONS_AT_APPLIED_STAGE_WITHDRAWN = 'APPLICATIONS_AT_APPLIED_STAGE_WITHDRAWN',
  APPLICATIONS_AT_SCREENING_STAGE = 'APPLICATIONS_AT_SCREENING_STAGE',
  APPLICATIONS_AT_SCREENING_STAGE_PASSED = 'APPLICATIONS_AT_SCREENING_STAGE_PASSED',
  APPLICATIONS_AT_SCREENING_STAGE_REJECTED = 'APPLICATIONS_AT_SCREENING_STAGE_REJECTED',
  APPLICATIONS_AT_SCREENING_STAGE_WITHDRAWN = 'APPLICATIONS_AT_SCREENING_STAGE_WITHDRAWN',
  APPLICATIONS_AT_1ST_INTERVIEW_STAGE = 'APPLICATIONS_AT_1ST_INTERVIEW_STAGE',
  APPLICATIONS_AT_1ST_INTERVIEW_STAGE_PASSED = 'APPLICATIONS_AT_1ST_INTERVIEW_STAGE_PASSED',
  APPLICATIONS_AT_1ST_INTERVIEW_STAGE_REJECTED = 'APPLICATIONS_AT_1ST_INTERVIEW_STAGE_REJECTED',
  APPLICATIONS_AT_1ST_INTERVIEW_STAGE_WITHDRAWN = 'APPLICATIONS_AT_1ST_INTERVIEW_STAGE_WITHDRAWN',
  APPLICATIONS_AT_2ND_INTERVIEW_STAGE = 'APPLICATIONS_AT_2ND_INTERVIEW_STAGE',
  APPLICATIONS_AT_2ND_INTERVIEW_STAGE_PASSED = 'APPLICATIONS_AT_2ND_INTERVIEW_STAGE_PASSED',
  APPLICATIONS_AT_2ND_INTERVIEW_STAGE_REJECTED = 'APPLICATIONS_AT_2ND_INTERVIEW_STAGE_REJECTED',
  APPLICATIONS_AT_2ND_INTERVIEW_STAGE_WITHDRAWN = 'APPLICATIONS_AT_2ND_INTERVIEW_STAGE_WITHDRAWN',
  APPLICATIONS_AT_3RD_INTERVIEW_STAGE = 'APPLICATIONS_AT_3RD_INTERVIEW_STAGE',
  APPLICATIONS_AT_3RD_INTERVIEW_STAGE_PASSED = 'APPLICATIONS_AT_3RD_INTERVIEW_STAGE_PASSED',
  APPLICATIONS_AT_3RD_INTERVIEW_STAGE_REJECTED = 'APPLICATIONS_AT_3RD_INTERVIEW_STAGE_REJECTED',
  APPLICATIONS_AT_3RD_INTERVIEW_STAGE_WITHDRAWN = 'APPLICATIONS_AT_3RD_INTERVIEW_STAGE_WITHDRAWN',
  APPLICATIONS_AT_OFFERED_STAGE = 'APPLICATIONS_AT_OFFERED_STAGE',
  APPLICATIONS_AT_OFFERED_STAGE_PASSED = 'APPLICATIONS_AT_OFFERED_STAGE_PASSED',
  APPLICATIONS_AT_OFFERED_STAGE_REJECTED = 'APPLICATIONS_AT_OFFERED_STAGE_REJECTED',
  APPLICATIONS_AT_OFFERED_STAGE_WITHDRAWN = 'APPLICATIONS_AT_OFFERED_STAGE_WITHDRAWN',
  APPLICATIONS_AT_JOINED_STAGE = 'APPLICATIONS_AT_JOINED_STAGE',
}

// This kind of mapping will be updated and more centralized when we start using
// other metric groups on the frontend, not just recruitment
// We'll probably have a concept of frontend_Metric_id or frontend_metric_group then
export const recruitmentStageToFrontendMetricGroupIdMap: Record<RecruitmentStages, FrontendMetricGroupId> = {
  [RecruitmentStages.APPLICATIONS_AT_APPLIED_STAGE]: FrontendMetricGroupId.APPLICATIONS_AT_APPLIED_STAGE,
  [RecruitmentStages.APPLICATIONS_AT_APPLIED_STAGE_PASSED]: FrontendMetricGroupId.APPLICATIONS_AT_APPLIED_STAGE_PASSED,
  [RecruitmentStages.APPLICATIONS_AT_APPLIED_STAGE_REJECTED]:
    FrontendMetricGroupId.APPLICATIONS_AT_APPLIED_STAGE_REJECTED,
  [RecruitmentStages.APPLICATIONS_AT_APPLIED_STAGE_WITHDRAWN]:
    FrontendMetricGroupId.APPLICATIONS_AT_APPLIED_STAGE_WITHDRAWN,
  [RecruitmentStages.APPLICATIONS_AT_SCREENING_STAGE]: FrontendMetricGroupId.APPLICATIONS_AT_SCREENING_STAGE,
  [RecruitmentStages.APPLICATIONS_AT_SCREENING_STAGE_PASSED]:
    FrontendMetricGroupId.APPLICATIONS_AT_SCREENING_STAGE_PASSED,
  [RecruitmentStages.APPLICATIONS_AT_SCREENING_STAGE_REJECTED]:
    FrontendMetricGroupId.APPLICATIONS_AT_SCREENING_STAGE_REJECTED,
  [RecruitmentStages.APPLICATIONS_AT_SCREENING_STAGE_WITHDRAWN]:
    FrontendMetricGroupId.APPLICATIONS_AT_SCREENING_STAGE_WITHDRAWN,
  [RecruitmentStages.APPLICATIONS_AT_1ST_INTERVIEW_STAGE]: FrontendMetricGroupId.APPLICATIONS_AT_1ST_INTERVIEW_STAGE,
  [RecruitmentStages.APPLICATIONS_AT_1ST_INTERVIEW_STAGE_PASSED]:
    FrontendMetricGroupId.APPLICATIONS_AT_1ST_INTERVIEW_STAGE_PASSED,
  [RecruitmentStages.APPLICATIONS_AT_1ST_INTERVIEW_STAGE_REJECTED]:
    FrontendMetricGroupId.APPLICATIONS_AT_1ST_INTERVIEW_STAGE_REJECTED,
  [RecruitmentStages.APPLICATIONS_AT_1ST_INTERVIEW_STAGE_WITHDRAWN]:
    FrontendMetricGroupId.APPLICATIONS_AT_1ST_INTERVIEW_STAGE_WITHDRAWN,
  [RecruitmentStages.APPLICATIONS_AT_2ND_INTERVIEW_STAGE]: FrontendMetricGroupId.APPLICATIONS_AT_2ND_INTERVIEW_STAGE,
  [RecruitmentStages.APPLICATIONS_AT_2ND_INTERVIEW_STAGE_PASSED]:
    FrontendMetricGroupId.APPLICATIONS_AT_2ND_INTERVIEW_STAGE_PASSED,
  [RecruitmentStages.APPLICATIONS_AT_2ND_INTERVIEW_STAGE_REJECTED]:
    FrontendMetricGroupId.APPLICATIONS_AT_2ND_INTERVIEW_STAGE_REJECTED,
  [RecruitmentStages.APPLICATIONS_AT_2ND_INTERVIEW_STAGE_WITHDRAWN]:
    FrontendMetricGroupId.APPLICATIONS_AT_2ND_INTERVIEW_STAGE_WITHDRAWN,
  [RecruitmentStages.APPLICATIONS_AT_3RD_INTERVIEW_STAGE]: FrontendMetricGroupId.APPLICATIONS_AT_3RD_INTERVIEW_STAGE,
  [RecruitmentStages.APPLICATIONS_AT_3RD_INTERVIEW_STAGE_PASSED]:
    FrontendMetricGroupId.APPLICATIONS_AT_3RD_INTERVIEW_STAGE_PASSED,
  [RecruitmentStages.APPLICATIONS_AT_3RD_INTERVIEW_STAGE_REJECTED]:
    FrontendMetricGroupId.APPLICATIONS_AT_3RD_INTERVIEW_STAGE_REJECTED,
  [RecruitmentStages.APPLICATIONS_AT_3RD_INTERVIEW_STAGE_WITHDRAWN]:
    FrontendMetricGroupId.APPLICATIONS_AT_3RD_INTERVIEW_STAGE_WITHDRAWN,
  [RecruitmentStages.APPLICATIONS_AT_OFFERED_STAGE]: FrontendMetricGroupId.APPLICATIONS_AT_OFFERED_STAGE,
  [RecruitmentStages.APPLICATIONS_AT_OFFERED_STAGE_PASSED]: FrontendMetricGroupId.APPLICATIONS_AT_OFFERED_STAGE_PASSED,
  [RecruitmentStages.APPLICATIONS_AT_OFFERED_STAGE_REJECTED]:
    FrontendMetricGroupId.APPLICATIONS_AT_OFFERED_STAGE_REJECTED,
  [RecruitmentStages.APPLICATIONS_AT_OFFERED_STAGE_WITHDRAWN]:
    FrontendMetricGroupId.APPLICATIONS_AT_OFFERED_STAGE_WITHDRAWN,
  [RecruitmentStages.APPLICATIONS_AT_JOINED_STAGE]: FrontendMetricGroupId.APPLICATIONS_AT_JOINED_STAGE,
};

export const recruitmentFrontendMetricGroups: FrontendMetricGroupId[] = [
  RecruitmentStages.APPLICATIONS_AT_APPLIED_STAGE,
  RecruitmentStages.APPLICATIONS_AT_APPLIED_STAGE_PASSED,
  RecruitmentStages.APPLICATIONS_AT_APPLIED_STAGE_REJECTED,
  RecruitmentStages.APPLICATIONS_AT_APPLIED_STAGE_WITHDRAWN,
  RecruitmentStages.APPLICATIONS_AT_SCREENING_STAGE,
  RecruitmentStages.APPLICATIONS_AT_SCREENING_STAGE_PASSED,
  RecruitmentStages.APPLICATIONS_AT_SCREENING_STAGE_REJECTED,
  RecruitmentStages.APPLICATIONS_AT_SCREENING_STAGE_WITHDRAWN,
  RecruitmentStages.APPLICATIONS_AT_1ST_INTERVIEW_STAGE,
  RecruitmentStages.APPLICATIONS_AT_1ST_INTERVIEW_STAGE_PASSED,
  RecruitmentStages.APPLICATIONS_AT_1ST_INTERVIEW_STAGE_REJECTED,
  RecruitmentStages.APPLICATIONS_AT_1ST_INTERVIEW_STAGE_WITHDRAWN,
  RecruitmentStages.APPLICATIONS_AT_2ND_INTERVIEW_STAGE,
  RecruitmentStages.APPLICATIONS_AT_2ND_INTERVIEW_STAGE_PASSED,
  RecruitmentStages.APPLICATIONS_AT_2ND_INTERVIEW_STAGE_REJECTED,
  RecruitmentStages.APPLICATIONS_AT_2ND_INTERVIEW_STAGE_WITHDRAWN,
  RecruitmentStages.APPLICATIONS_AT_3RD_INTERVIEW_STAGE,
  RecruitmentStages.APPLICATIONS_AT_3RD_INTERVIEW_STAGE_PASSED,
  RecruitmentStages.APPLICATIONS_AT_3RD_INTERVIEW_STAGE_REJECTED,
  RecruitmentStages.APPLICATIONS_AT_3RD_INTERVIEW_STAGE_WITHDRAWN,
  RecruitmentStages.APPLICATIONS_AT_OFFERED_STAGE,
  RecruitmentStages.APPLICATIONS_AT_OFFERED_STAGE_PASSED,
  RecruitmentStages.APPLICATIONS_AT_OFFERED_STAGE_REJECTED,
  RecruitmentStages.APPLICATIONS_AT_OFFERED_STAGE_WITHDRAWN,
  RecruitmentStages.APPLICATIONS_AT_JOINED_STAGE,
].map((s) => recruitmentStageToFrontendMetricGroupIdMap[s]);

export enum ApplicationStages {
  APPLIED = 'Applied',
  SCREENING = 'Screening',
  FIRST_INTERVIEW_PASSED = '1st Interview Passed',
  SECOND_INTERVIEW_PASSED = '2nd Interview Passed',
  THIRD_INTERVIEW_PASSED = '3rd Interview Passed',
  LAST_INTERVIEW_PASSED = 'Last Interview Passed',
  OFFERED = 'Offered',
  ACCEPTED = 'Accepted',
  JOINED = 'Joined',
}

export enum RequisitionActivities {
  APPLICATION = 'Application',
  SCREENING = 'Screening',
  INTERVIEW = 'Interview',
  OFFER = 'Offer',
  HIRE = 'Hire',
}

// tslint:disable-next-line: no-namespace
export namespace ApplicationStages {
  export const orderedList: ApplicationStages[] = [
    ApplicationStages.APPLIED,
    ApplicationStages.SCREENING,
    ApplicationStages.FIRST_INTERVIEW_PASSED,
    ApplicationStages.SECOND_INTERVIEW_PASSED,
    ApplicationStages.THIRD_INTERVIEW_PASSED,
    ApplicationStages.LAST_INTERVIEW_PASSED,
    ApplicationStages.OFFERED,
    ApplicationStages.ACCEPTED,
    ApplicationStages.JOINED,
  ];
  export const sortOrder: Ord<ApplicationStages> = {
    compare: (s1: ApplicationStages, s2: ApplicationStages): Ordering => {
      const s1Index = orderedList.findIndex((s) => s === s1);
      const s2Index = orderedList.findIndex((s) => s === s2);
      if (s1Index && s2Index) {
        return s1Index < s2Index ? Ordering.LT : s1Index > s2Index ? Ordering.GT : Ordering.EQ;
      } else {
        return Ordering.EQ;
      }
    },
  };

  export const translationKeys: Record<ApplicationStages, string> = {
    [ApplicationStages.APPLIED]: 'common:filterTray.stages.applied',
    [ApplicationStages.SCREENING]: 'common:filterTray.stages.screening',
    [ApplicationStages.FIRST_INTERVIEW_PASSED]: 'common:filterTray.stages.firstInterviewPassed',
    [ApplicationStages.SECOND_INTERVIEW_PASSED]: 'common:filterTray.stages.secondInterviewPassed',
    [ApplicationStages.THIRD_INTERVIEW_PASSED]: 'common:filterTray.stages.thirdInterviewPassed',
    [ApplicationStages.LAST_INTERVIEW_PASSED]: 'common:filterTray.stages.lastInterviewPassed',
    [ApplicationStages.OFFERED]: 'common:filterTray.stages.offered',
    [ApplicationStages.ACCEPTED]: 'common:filterTray.stages.accepted',
    [ApplicationStages.JOINED]: 'common:filterTray.stages.joined',
  };

  export const getTranslation = (stage: ApplicationStages) => i18n.t(ApplicationStages.translationKeys[stage]);
}

export class RecruitmentStore {
  public domainDependencyStore: DomainDependencyStore;
  public companyStore: CompanyStore;
  public timeSliderStore: TimeSliderStore;
  public filterStore: FilterStore;

  constructor(
    filterStore: FilterStore,
    domainDependencyStore: DomainDependencyStore,
    companyStore: CompanyStore,
    timeSliderStore: TimeSliderStore
  ) {
    this.filterStore = filterStore;
    this.domainDependencyStore = domainDependencyStore;
    this.companyStore = companyStore;
    this.timeSliderStore = timeSliderStore;

    let firstRun = true;
    autorun(() => {
      const storeData = JSON.stringify({ masterFilters: this.masterFilters });
      if (!firstRun) {
        localStore.setAsync('recruitmentStore', storeData);
      }
      firstRun = false;
    });
  }

  @computed
  public get RecruitmentDataTypes(): DataTypes[] {
    // Adding survey types for backward compatibility but they might not be needed here
    return [DataTypes.APPLICATION, DataTypes.OFFER, DataTypes.RECRUITERJOBS, DataTypes.JOB, DataTypes.QUESTIONANSWER];
  }

  public isRecruitmentDataType = (dataType: DataTypes) => {
    return this.RecruitmentDataTypes.includes(dataType);
  };

  @observable
  public masterFilters: Partial<Record<DataTypes, ApiMasterDataQueryFilterItem[]>> = {
    [DataTypes.APPLICATION]: [],
    [DataTypes.OFFER]: [],
    [DataTypes.JOB]: [],
    [DataTypes.RECRUITERJOBS]: [],
    [DataTypes.QUESTIONANSWER]: [],
    [DataTypes.INTERVIEW]: [],
  };

  @observable
  public sources: ApiHierarchyItem[] = [];

  @observable
  public commonLegendsHashmap: any = {};

  @observable
  public commonLegendLabels: string[] = [];

  @observable
  public recruiterJobMap: any = {};

  @observable
  public recruiterActivityDimension: string = 'OFFICE';

  @observable
  public recruitmentDataViewTabConfig: TabConfig[] = [];

  @observable
  public enabledRecruitmentActivities: string[] = Object.values(RequisitionActivities);

  public proactiveSources = ['In person event', 'Prospecting', 'Agency', 'Sourcing'];

  public get hierarchichalSources(): ApiHierarchyItem[] {
    const result = this.sources.reduce(
      (result, source) => {
        if (this.proactiveSources.includes(source.name)) {
          result[1].subItems?.push(source);
        } else {
          result[0].subItems?.push(source);
        }
        return result;
      },
      [
        { name: 'Inbound', subItems: [] },
        { name: 'Outbound', subItems: [] },
      ] as ApiHierarchyItem[]
    );
    return result;
  }

  @action
  public getDataViewTabConfigs = async () => {
    const { startDate, endDate } = this.timeSliderStore.timeSliderConfig;

    const dataViewTabs = [
      DataTypes.JOB,
      DataTypes.RECRUITERJOBS,
      DataTypes.APPLICATION,
      DataTypes.OFFER,
      DataTypes.INTERVIEW,
    ];

    const config: TabConfig[] = [];
    const dataTypeToDataFieldsMap: Record<DataTypes, DataFieldWithDataType[]> = Object.fromEntries(
      await Promise.all(
        dataViewTabs.map(async (t) => [t, await GetUserVisibleNonConfidentialNonNullFieldsForDataTypeService(t)])
      )
    );
    for (const dataType of dataViewTabs) {
      const latestVersion = this.domainDependencyStore.getLatestVersion(dataType);
      const dataFields = dataTypeToDataFieldsMap[dataType];
      if (dataFields.length && latestVersion) {
        const query: ApiMasterDataAdvancedQuery = {
          dataType,
          dimensions: dataFields.map((field) => toAdvancedDimension(field)),
          filterItems: [
            ...combineFilters(this.masterFilters[dataType]),
            ...this.getTimeFiltersForDataView(dataType, startDate, endDate),
          ],
        };
        if (dataType === DataTypes.OFFER) {
          const candidateNameFields = [
            {
              dataType: DataTypes.APPLICATION,
              property: ApplicationDataFields.CANDIDATE_FIRST_NAME,
            },
            {
              dataType: DataTypes.APPLICATION,
              property: ApplicationDataFields.CANDIDATE_LAST_NAME,
            },
          ].filter((f) =>
            dataTypeToDataFieldsMap[DataTypes.APPLICATION].deepCompareContains(
              toDataFieldWithDataType(f.dataType, f.property)
            )
          );
          if (candidateNameFields.length) {
            query.dimensions?.splice(1, 0, ...candidateNameFields);
            query.joins = [applicationToOfferTableJoin];
          }
        }

        config.push({
          type: TabConfigType.TabConfigWithQueries,
          title: `pages:dashboards.recruitment.dataView.${dataType.toCamel().replace('_', '')}`,
          value: {
            queriesWithType: [toApiMasterDataAdvancedQueryWithTypeInfo(query)],
          },
        });
      }
    }

    this.recruitmentDataViewTabConfig = config;
  };

  @action
  public handleRecruiterActivityDimension = (_event: React.ChangeEvent<{}>, value: string) => {
    this.recruiterActivityDimension = value;
  };

  public setRecruiterActivityDimension = (dimension: string) => {
    runInAction(() => {
      this.recruiterActivityDimension = dimension;
    });
  };

  @action
  public resetFilters() {
    this.masterFilters = {
      [DataTypes.APPLICATION]: [],
      [DataTypes.OFFER]: [],
      [DataTypes.JOB]: [],
      [DataTypes.QUESTIONANSWER]: [],
      [DataTypes.INTERVIEW]: [],
    };
    this.filterStore.resetFilters();
  }

  @action
  public toggleFilter(filter: ApiMasterDataQueryFilterItem, dataType: DataTypes) {
    // Separate handling of Employee Filters for now
    const filters = this.masterFilters[dataType] ?? [];

    if (dataType === 'EMPLOYEE') {
      this.filterStore.toggleFilter({ filter });
    }
    // Special Logic for Sources to do inbound and outbound:

    const { property, operation, values } = filter;

    const correctFilter: ApiMasterDataQueryFilterItem = {
      property,
      operation,
      values,
      dataType,
    };

    // ideally 'ALL' filters shouldn't be sent at this level
    // but since we want to merge all work with filters into filterStore,
    // adding this check for now
    if (values.some((v) => v.includes(i18n.t(AllDisplayString)))) {
      const matchingFilters = filters.filter((item) => item.property === property && item.operation === operation);

      matchingFilters.forEach((matchingFilter) => {
        const index = filters.indexOf(matchingFilter);
        filters.splice(index, 1);
      });

      return;
    }

    if (!hasUndefined(filter)) {
      const index = filters.findIndex(
        (item) =>
          item.property === property &&
          item.operation === operation &&
          JSON.stringify(item.values[0]) === JSON.stringify(values[0])
      );
      if (index !== -1) {
        filters.splice(index, 1);
      } else {
        filters.push(correctFilter);
      }

      this.masterFilters = JSON.parse(JSON.stringify(this.masterFilters));
    }
  }

  @action
  public async loadSources() {
    // TODO: Eventually need to remove this from store as well
    // and use react-query hooks but keeping it for now
    // This isn't used in FilterTray so we can skip migrating this for now.
    const query: ApiMasterDataQuery = {
      dataType: DataTypes.APPLICATION,
      dimensions: [ApplicationDataFields.SOURCE_TYPE],
    };
    const result = await cachedMasterDataApiService.executeQuery(this.companyStore.domain, query);
    runInAction(() => {
      this.sources = (result.dataPoints || []).map((dataPoint) => {
        return { name: dataPoint.dimensions[0].value };
      });
    });
  }

  @action
  public toggleCommonLegend(key: string) {
    if (key in this.commonLegendsHashmap) {
      this.commonLegendsHashmap[key] = !this.commonLegendsHashmap[key];
    }
  }

  @action
  public setCommonLegendHashmap(labels: string[]) {
    this.commonLegendLabels = labels;
    this.commonLegendsHashmap = labels.reduce((result: any, label) => {
      result[label] = true;
      return result;
    }, {});
  }

  @action
  public applyDrillDownFilters(filters: ApiMasterDataQueryFilterItem[], baseDim: DataFieldWithDataType) {
    this.masterFilters[baseDim.dataType] = [
      ...(this.masterFilters[baseDim.dataType]?.filter((f) => !compareFilterToDim(f, baseDim)) ?? []),
      ...filters.filter((f) => !hasUndefined(f)),
    ];
  }

  private getTimeFiltersForDataView = (
    dataType: DataTypes,
    startDate: string,
    endDate: string
  ): ApiMasterDataQueryFilterItem[] => {
    const latestVersion = this.domainDependencyStore.getLatestVersion(dataType);
    if (latestVersion) {
      if (dataType === DataTypes.JOB) {
        return [
          {
            operation: Operations.GREATER_THAN_OR_EQUAL_TO,
            property: JobDataFields.OPENED_AT,
            values: [startDate],
            dataType,
          },
          {
            operation: Operations.LESS_THAN_OR_EQUAL_TO,
            property: JobDataFields.OPENED_AT,
            values: [endDate],
            dataType,
          },
          getVersionFilter(latestVersion, Operations.EQUAL, dataType),
        ];
      } else if (dataType === DataTypes.EMPLOYEE) {
        return getVersionFilterItems(endDate);
      } else if (dataType === DataTypes.RECRUITERJOBS) {
        return [
          {
            property: RecruiterJobsDataFields.RECRUITER_TEMPORALITY,
            operation: Operations.EQUAL,
            values: [EmploymentTemporality.PRESENT, EmploymentTemporality.PAST],
            dataType,
          },
          getVersionFilter(latestVersion, Operations.EQUAL, dataType),
        ];
      } else if (dataType === DataTypes.APPLICATION) {
        return [
          {
            operation: Operations.GREATER_THAN_OR_EQUAL_TO,
            property: ApplicationDataFields.APPLIED_AT,
            values: [startDate],
            dataType,
          },
          {
            operation: Operations.LESS_THAN_OR_EQUAL_TO,
            property: ApplicationDataFields.APPLIED_AT,
            values: [endDate],
            dataType,
          },
          getVersionFilter(latestVersion, Operations.EQUAL, dataType),
        ];
      } else if (dataType === DataTypes.OFFER) {
        return [
          {
            operation: Operations.GREATER_THAN_OR_EQUAL_TO,
            property: OfferDataFields.RESOLVED_AT,
            values: [startDate],
            dataType,
          },
          {
            operation: Operations.LESS_THAN_OR_EQUAL_TO,
            property: OfferDataFields.RESOLVED_AT,
            values: [endDate],
            dataType,
          },
          getVersionFilter(latestVersion, Operations.EQUAL, dataType),
        ];
      } else if (dataType === DataTypes.INTERVIEW) {
        return [
          {
            operation: Operations.GREATER_THAN_OR_EQUAL_TO,
            property: InterviewDataFields.EFFECTIVE_DATE,
            values: [startDate],
            dataType,
          },
          {
            operation: Operations.LESS_THAN_OR_EQUAL_TO,
            property: InterviewDataFields.EFFECTIVE_DATE,
            values: [endDate],
            dataType,
          },
        ];
      }
    }
    return [];
  };
}
