import { ApiMasterDataQueryFilterItem } from '../../api/api-interfaces';
import { baseAuthedTracker } from './baseTracker';

const FILTER_APPLIED_EVENT = '[Filter] Applied';
const FILTER_RESETTED_EVENT = '[Filter] Resetted';
const FILTER_OPENED_EVENT = '[Filter] Opened';
const FILTER_CLOSED_EVENT = '[Filter] Closed';

export const trackFilterApply = (filter: ApiMasterDataQueryFilterItem) => {
  baseAuthedTracker(FILTER_APPLIED_EVENT, {
    filter,
  });
};

/**
 * Call this when user reset ALL filter
 */
export const trackFilterReset = (actionData: any = {}) => {
  baseAuthedTracker(FILTER_RESETTED_EVENT, { ...actionData });
};

/**
 * Call this when user open FilterTray
 */
export const trackFilterOpen = (actionData: any = {}) => {
  baseAuthedTracker(FILTER_OPENED_EVENT, { ...actionData });
};

/**
 * Call this when user hide FilterTray
 */
export const trackFilterClose = (actionData: any = {}) => {
  baseAuthedTracker(FILTER_CLOSED_EVENT, { ...actionData });
};
