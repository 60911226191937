import { DataFieldWithDataType } from '../../../../../common-types';
import { queryClient } from '../../../../app';
import { ApiHierarchyItem } from '../../../api/api-interfaces';
import { getDatesInRange } from '../../../filter/utils';
import { getQueryFnForDimension, getUseDataHookQueryKey, QueryFnForDimensionOptions } from './dataHooks';

export const getDataHierarchyFromReactQueryCacheOrFetch = async (
  dimension: DataFieldWithDataType,
  options: QueryFnForDimensionOptions
) => {
  const dates = getDatesInRange(options.timeSliderConfig);
  const queryKey = getUseDataHookQueryKey(dimension, dates);
  const queryFn = getQueryFnForDimension(dimension);
  const cachedData = queryClient.getQueryData(queryKey) as ApiHierarchyItem[];
  if (cachedData) {
    return cachedData;
  } else {
    const data = await queryClient.fetchQuery({ queryKey, queryFn: () => queryFn(options) });
    return data;
  }
};
