import { IReactionDisposer, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';
import React, { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Switch } from 'react-router';
import { DetailedErrorBoundary } from '../DetailedErrorBoundary';
import { customSnackbarAction } from '../common/components/common-snackbar';
import { PrivateRoute } from '../common/components/routing/private-route';
import { Shell } from '../common/components/shell/Shell';
import LoadingOverlay from '../common/components/utils/LoadingOverlay';
import { environmentService } from '../common/environment/environment-service';
import useNetwork from '../common/hooks/useNetwork';
import { DashboardRouteProps, dashboardRoutes, pageRoutes } from '../common/routes';
import { rootStore } from '../common/store/root-store';
import { AuthGuard } from './pre-render-app-guards/AuthGuard';
import { DependenciesGuard } from './pre-render-app-guards/DependenciesGuard';
import { DomainDependenciesGuard } from './pre-render-app-guards/DomainDependenciesGuard';
import { DomainGuard } from './pre-render-app-guards/DomainGuard';
import { IdleTimerGuard } from './pre-render-app-guards/IdleTimerGuard';
import { InitialDependenciesGuard } from './pre-render-app-guards/InitialDependenciesGuard';
import { PermissionsGuard } from './pre-render-app-guards/PermissionsGuard';

// this override is just for dev/debug purposes
const allowAllDashboardRoutes = false;

const getPermittedDashboardRoutes = () => {
  const { allowedDashboards } = rootStore.dashboardStore;
  const permittedDashboards = allowedDashboards.filter((db) => db.enabled);
  let permittedDashboardRoutes: DashboardRouteProps[];
  if (environmentService.isLocalhost && allowAllDashboardRoutes) {
    permittedDashboardRoutes = dashboardRoutes;
  } else {
    permittedDashboardRoutes = dashboardRoutes.filter(
      (route) => permittedDashboards && permittedDashboards.some(({ link }) => route.path && route.path.includes(link))
    );
  }
  return permittedDashboardRoutes;
};

const RenderPrivateRoutes = () => {
  const { companyLandingPage } = rootStore.companySettingsStore;
  const enabledPageRoutes = pageRoutes.filter((r) => {
    if (r.isEnabled) {
      return r.isEnabled();
    } else {
      return true;
    }
  });
  const permittedDashboardRoutes = getPermittedDashboardRoutes();
  return (
    <Shell>
      <Suspense fallback={<LoadingOverlay />}>
        <Switch>
          {enabledPageRoutes.map((route) => (
            <PrivateRoute key={route.id} {...route} />
          ))}
          {permittedDashboardRoutes.map((route) => (
            <PrivateRoute key={route.id} {...route} />
          ))}
          <Redirect from="/" to={companyLandingPage} />
        </Switch>
      </Suspense>
    </Shell>
  );
};

export const PrivateRoutes = observer(() => {
  const { errorDescriptions, queryErrors } = rootStore.errorStore;
  const isOnline = useNetwork();
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let reactionToErrorDisposer: IReactionDisposer;

  useEffect(() => {
    reactionToErrorDisposer = reaction(
      () => rootStore.errorStore.queryErrors.length,
      (length) => {
        const graphQLError = queryErrors[length - 1];
        const errorText = errorDescriptions[graphQLError.code] ?? errorDescriptions.default;
        const message = t(errorText, {
          errorReference: graphQLError.errorReference,
        }) as unknown as string;
        enqueueSnackbar(message, { variant: 'error' });
      }
    );

    return () => {
      reactionToErrorDisposer();
    };
  }, []);

  useEffect(() => {
    if (!isOnline) {
      enqueueSnackbar(t('common:commonValues.offlineMessage'), { persist: true, action: customSnackbarAction });
    } else {
      closeSnackbar();
    }
  }, [isOnline]);

  return (
    <AuthGuard>
      <IdleTimerGuard>
        <InitialDependenciesGuard>
          <PermissionsGuard>
            <DomainGuard>
              <DomainDependenciesGuard>
                <DependenciesGuard>
                  <DetailedErrorBoundary>
                    <RenderPrivateRoutes />
                  </DetailedErrorBoundary>
                </DependenciesGuard>
              </DomainDependenciesGuard>
            </DomainGuard>
          </PermissionsGuard>
        </InitialDependenciesGuard>
      </IdleTimerGuard>
    </AuthGuard>
  );
});
