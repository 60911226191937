import { action, observable, runInAction } from 'mobx';
import { ApiMasterDataQuery, ApiUser } from '../api/api-interfaces';
import { cachedMasterDataApiService } from '../api/master-data-service';
import { UserRolePreferences } from '../api/zod-schemas';
import { CompanyStore } from '../company/company-store';
import { LanguageStore } from '../localization/language-store';
import { InitialDependencyStore } from '../startup/initial-dependency-store';

export type StateType = 'PENDING' | 'DONE' | 'ERROR';

interface EmployeeUser {
  employeeId: string;
  memberId: string;
}

export class UserStore {
  @observable
  public currentUser: ApiUser | null = null;

  @observable
  public currentUserFetched: boolean = false;

  @observable
  public userRolePreferences: UserRolePreferences | null = null;

  @observable
  public userRolePreferencesFetched: boolean = false;

  @observable
  public message: string = '';

  @observable
  public state: StateType | null = null;

  @observable
  public domain: string = '';

  @observable
  public currentEmployeeUser: EmployeeUser | null = null;

  public companyStore: CompanyStore;
  public languageStore: LanguageStore;
  public initialDependencyStore: InitialDependencyStore;

  constructor(
    companyStore: CompanyStore,
    initialDependencyStore: InitialDependencyStore,
    languageStore: LanguageStore
  ) {
    this.companyStore = companyStore;
    this.languageStore = languageStore;
    this.initialDependencyStore = initialDependencyStore;
  }

  @action
  public getCurrentEmployeeUser = async () => {
    if (this.currentUser && this.currentUser.email) {
      // this means its not an error, i.e. user found
      const employeeUserQuery: ApiMasterDataQuery = {
        dataType: 'EMPLOYEE',
        dimensions: ['EMPLOYEE_ID', 'MEMBER_ID'],
        filterItems: [
          {
            dataType: 'EMPLOYEE',
            operation: 'EQUAL',
            property: 'COMPANY_EMAIL',
            values: [this.currentUser.email],
          },
        ],
        limitedToPermittedPopulation: false,
      };

      const employeeUserResult = await cachedMasterDataApiService.executeQuery(
        this.companyStore.domain,
        employeeUserQuery
      );

      if (employeeUserResult && employeeUserResult.dataPoints && employeeUserResult.dataPoints.length) {
        this.currentEmployeeUser = {
          employeeId: employeeUserResult.dataPoints[0].dimensions[0].value,
          memberId: employeeUserResult.dataPoints[0].dimensions[1].value,
        };
      }
    }
  };

  @action
  public async loadUserInfo() {
    const user = this.initialDependencyStore.getUser();
    this.currentUser = user;
    this.currentUserFetched = true;
    return this.currentUser;
  }

  @action
  public async loadUserRolePreference() {
    const { domain } = this.companyStore;
    if (domain) {
      const userRolePreferences = this.initialDependencyStore.getLatestUserRolePreferences();
      runInAction(() => {
        this.userRolePreferences = userRolePreferences;
        this.userRolePreferencesFetched = true;
      });
    }
    return this.userRolePreferences;
  }

  @action
  public reset() {
    this.state = null;
    this.message = '';
  }
}
