import i18n from '../../../../i18n';
import { UndefinedValue } from '../../constants/systemValuesTranslation';
import { $UndefinedValueIndicator$, ApiHierarchyItem, UndefinedDisplayKey } from '../api-interfaces';

export const getDataHierarchy = (levelsOfEntities: (string | null)[][]): ApiHierarchyItem[] => {
  let tree: ApiHierarchyItem[] = buildApiHierarchyItems(levelsOfEntities);
  tree = removeUnnecessaryNulls(tree);
  tree = formatNAValues(tree);
  tree = unsetLeafSubitems(tree);
  return tree;
};

const buildApiHierarchyItems = (levelsOfEntities: (string | null)[][]) => {
  const tree: ApiHierarchyItem[] = [];
  levelsOfEntities.forEach((entity) => {
    addEntityToTree(tree, entity, 0);
  });
  return tree;
};

const findItemInTreeByName = (tree: ApiHierarchyItem[], name: string | null): ApiHierarchyItem | null => {
  return tree.find((item) => item.name === name) ?? null;
};

// WARNING: self-recursive method!
const addEntityToTree = (tree: ApiHierarchyItem[], levels: (string | null)[], level: number) => {
  // we have reached the max level
  if (levels.length <= level) {
    return;
  }
  const levelValue: string = levels[level] ?? '';

  let next: ApiHierarchyItem;

  const maybeItem = findItemInTreeByName(tree, levelValue);
  if (maybeItem) {
    next = maybeItem;
  } else {
    const newItem: ApiHierarchyItem = { name: levelValue ?? '', subItems: [] };
    tree.push(newItem);
    next = newItem;
  }

  addEntityToTree(next.subItems ?? [], levels, level + 1);
};

const unsetLeafSubitems = (tree: ApiHierarchyItem[]) => {
  tree.forEach((elem) => {
    if (elem.subItems && elem.subItems.length === 0) {
      delete elem.subItems;
    } else {
      if (elem.subItems) {
        elem.subItems = unsetLeafSubitems(elem.subItems);
      }
    }
  });
  return tree;
};

const removeUnnecessaryNulls = (tree: ApiHierarchyItem[]): ApiHierarchyItem[] => {
  return tree.filter((t) => {
    if (t.subItems) {
      t.subItems = removeUnnecessaryNulls(t.subItems);
    }
    if (t.name) {
      return true;
    } else {
      return tree.length > 1 || (t.subItems && t.subItems.length > 0);
    }
  });
};

const formatNAValues = (tree: ApiHierarchyItem[], parentName?: string) => {
  const undefinedValueName = parentName ? `${$UndefinedValueIndicator$}[${parentName}]` : UndefinedValue;
  tree.forEach((elem) => {
    if (!elem.name) {
      // NA Value
      elem.name = undefinedValueName;
    }
    if (elem.subItems) {
      const newParentName = !elem.name.includes($UndefinedValueIndicator$) ? elem.name : i18n.t(UndefinedDisplayKey);
      elem.subItems = formatNAValues(elem.subItems, newParentName);
    }
  });
  return tree;
};
