import { ApiMasterDataAdvancedQuery, ApiMasterDataQuery, ApiMasterDataQueryResponse } from '../api/api-interfaces';
import { BenchmarkConfig, cachedMasterDataApiService } from '../api/master-data-service';
import { rootStore } from '../store/root-store';

export const GetResponseForMasterQueryService = async (
  query: ApiMasterDataQuery,
  benchmarkConfig?: BenchmarkConfig
): Promise<ApiMasterDataQueryResponse> => {
  return GetResponseForQueryService(query, benchmarkConfig) as Promise<ApiMasterDataQueryResponse>;
};

const GetResponseForQueryService = async (query: ApiMasterDataQuery, benchmarkConfig?: BenchmarkConfig) => {
  // This query fails if there is no data in the table instead of returning an empty response
  // See this discussion https://panalyt.slack.com/archives/C020B3RUR9U/p1717389224240879
  rootStore.loadingStateStore.loadStarted();
  try {
    return cachedMasterDataApiService.executeQuery(rootStore.companyStore.domain, query, benchmarkConfig);
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e);
  } finally {
    rootStore.loadingStateStore.loadFinished();
  }
};

export const GetResponseForAdvancedQueryService = async (
  query: ApiMasterDataAdvancedQuery,
  _benchmarkConfig?: BenchmarkConfig
) => {
  return cachedMasterDataApiService.executeAdvancedQuery(rootStore.companyStore.domain, query);
};
