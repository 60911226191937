import { computed } from 'mobx';
import { ApiHierarchyItem } from '../../../api/api-interfaces';
import { CompanySettingsStore } from '../../../company-settings-store';
import { ApplicationStatusValues } from '../../../constants/constant-backend-values';
import { applicationStatusValuesTranslationMap } from '../../../constants/systemValuesTranslation';
import { ApplicationStages } from '../../../recruitment/recruitment-store';

export class SynchronousDataStore {
  public companySettingsStore: CompanySettingsStore;
  constructor(companySettingsStore: CompanySettingsStore) {
    this.companySettingsStore = companySettingsStore;
  }

  // Employee

  @computed
  public get ageGroups(): ApiHierarchyItem[] {
    const ageGroups =
      this.companySettingsStore.ageGroups()?.map((ageGroup) => ({
        name: ageGroup.description,
      })) ?? [];
    return ageGroups;
  }

  @computed
  public get tenureGroups(): ApiHierarchyItem[] {
    const tenureGroups =
      this.companySettingsStore.tenureGroups()?.map((tenureGroup) => ({
        name: tenureGroup.description,
      })) ?? [];
    return tenureGroups;
  }

  // Recruitment

  public currentStageValues: ApiHierarchyItem[] = ApplicationStages.orderedList.map((stage) => ({
    name: stage,
    displayName: ApplicationStages.getTranslation(stage),
  }));

  public applicationStatusValues: ApiHierarchyItem[] = [
    {
      name: ApplicationStatusValues.Active,
      displayKey: applicationStatusValuesTranslationMap[ApplicationStatusValues.Active],
    },
    {
      name: ApplicationStatusValues.Rejected,
      displayKey: applicationStatusValuesTranslationMap[ApplicationStatusValues.Rejected],
    },
    {
      name: ApplicationStatusValues.Hired,
      displayKey: applicationStatusValuesTranslationMap[ApplicationStatusValues.Hired],
    },
  ];
}
