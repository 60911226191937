import { ApiMasterDataAdvancedQuery } from '../api/api-interfaces';
import { BenchmarkConfig } from '../api/employee-data-service/employee-data-service';
import { appCache } from '../cache/cache';
import { rootStore } from '../store/root-store';

export const GetResponsesForAdvancedQueriesService = async (
  queries: ApiMasterDataAdvancedQuery[],
  benchmarkConfig?: BenchmarkConfig
) => {
  const cacheKey = JSON.stringify({ queries, benchmarkConfig });
  const result = appCache.getFromCacheOrRequest(cacheKey, () => {
    return rootStore.masterDataApiService.executeAdvancedQueriesInBatch(
      rootStore.companyStore.domain,
      { queries },
      benchmarkConfig
    );
  });
  return result;
};
