import { action, computed, observable } from 'mobx';
import i18n from '../../../../i18n';
import { ApiMasterDataAdvancedQuery, ApiMasterDataQueryFilterItem } from '../../../common/api/api-interfaces';
import { DataTypes, Operations, TimeAndAttendanceMonthlyDataFields } from '../../../common/constants/constants';
import { getVersionFilter } from '../../../common/filter/common-filters';
import { GetResponseForAdvancedMasterQueryService } from '../../../common/services/GetResponseForAdvancedQueryService';
import { rootStore } from '../../../common/store/root-store';
import { getValueForMeasure } from '../../../common/utilFunctions/utils';
import { getOtHoursFilter } from './utils';

export enum OvertimePopulation {
  TOTAL = 'total',
  ONLYWITHOVERTIME = 'onlyOvertime',
  ELIGIBLEPOPULATION = 'eligiblePopulation',
}
export enum DAYS_AND_HOURS {
  DAYS = 'days',
  HOURS = 'hours',
}

export enum WorkHoursFactboxGranularity {
  TOTAL = 'total',
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day',
}

export enum OvertimeFactboxValues {
  TOTAL_HOURS = 'TOTAL_HOURS',
  AVG_MONTHLY_HOURS = 'AVG_MONTHLY_HOURS',
  AVG_MONTHLY_HOURS_PER_HEAD = 'AVG_MONTHLY_HOURS_PER_HEAD',
  NUM_EMPS_WITH_OT = 'EMPS_WITH_OT',
}

export enum OvertimeDataKeys {
  SUM_HOURS = 'sumHours',
  AVG_MONTHLY_HOURS = 'avgMonthHours',
  AVG_MONTHLY_HOURS_PER_HEAD = 'avgMonthlyHoursPerHead',
  NUM_EMPS_WITH_OT = 'numEmpsWithOvertime',
}

export const overtimeFactboxDetails: Record<OvertimeFactboxValues, { label: string; dataKey: string }> = {
  [OvertimeFactboxValues.TOTAL_HOURS]: {
    label: i18n.t('pages:dashboards.timeAndAttendance.overtime.totalHours'),
    dataKey: OvertimeDataKeys.SUM_HOURS,
  },
  [OvertimeFactboxValues.AVG_MONTHLY_HOURS]: {
    label: i18n.t('pages:dashboards.timeAndAttendance.overtime.avgMonthlyHours'),
    dataKey: OvertimeDataKeys.AVG_MONTHLY_HOURS,
  },
  [OvertimeFactboxValues.AVG_MONTHLY_HOURS_PER_HEAD]: {
    label: i18n.t('pages:dashboards.timeAndAttendance.overtime.avgMonthlyHoursPerHead'),
    dataKey: OvertimeDataKeys.AVG_MONTHLY_HOURS_PER_HEAD,
  },
  [OvertimeFactboxValues.NUM_EMPS_WITH_OT]: {
    label: i18n.t('pages:dashboards.timeAndAttendance.overtime.empsWithOt'),
    dataKey: OvertimeDataKeys.NUM_EMPS_WITH_OT,
  },
};

export type OvertimeHoursData = Record<OvertimeDataKeys, number>;
export interface DaysAndHours {
  days: number;
  hours: number;
}
class TimeAndAttendanceStore {
  @observable
  public daysAndHoursToggle: Record<string, boolean> = {
    [DAYS_AND_HOURS.DAYS]: true,
    [DAYS_AND_HOURS.HOURS]: true,
  };

  @observable
  public selectedFactboxGranularity: WorkHoursFactboxGranularity = WorkHoursFactboxGranularity.TOTAL;

  @observable
  public selectedOvertimeFactboxValue: OvertimeFactboxValues = OvertimeFactboxValues.TOTAL_HOURS;

  @observable
  public factBoxGranularityToMultiplicationFactorMap: Record<WorkHoursFactboxGranularity, number> = {
    [WorkHoursFactboxGranularity.TOTAL]: 1,
    [WorkHoursFactboxGranularity.MONTH]: 30,
    [WorkHoursFactboxGranularity.WEEK]: 5,
    [WorkHoursFactboxGranularity.DAY]: 1,
  };

  @observable
  public overtimeSliderMax: number | null = null;

  @observable
  public overtimeSliderMin: number | null = null;

  @observable
  public overtimeSliderValue: number[] | null = null;

  @action
  public overtimeSliderOnChange = (val: number[]) => (this.overtimeSliderValue = val);

  @action
  public loadOvertimeSliderRange = async () => {
    const query: ApiMasterDataAdvancedQuery = {
      dataType: DataTypes.TIMEANDATTENDANCEMONTHLY,
      measures: [
        {
          dataType: DataTypes.TIMEANDATTENDANCEMONTHLY,
          operation: Operations.MIN,
          property: TimeAndAttendanceMonthlyDataFields.NUM_OVERTIME_HOURS,
        },
        {
          dataType: DataTypes.TIMEANDATTENDANCEMONTHLY,
          operation: Operations.MAX,
          property: TimeAndAttendanceMonthlyDataFields.NUM_OVERTIME_HOURS,
        },
      ],
      filterItems: [
        getVersionFilter(
          rootStore.timeSliderStore.getOldestStartDate(),
          Operations.GREATER_THAN,
          DataTypes.TIMEANDATTENDANCEMONTHLY
        ),
        {
          dataType: DataTypes.TIMEANDATTENDANCEMONTHLY,
          operation: Operations.NOT_EQUAL,
          property: TimeAndAttendanceMonthlyDataFields.NUM_OVERTIME_HOURS,
          values: [0],
        },
      ],
    };
    const result = await GetResponseForAdvancedMasterQueryService(query);
    const min: number =
      Math.floor(
        getValueForMeasure(
          result?.dataPoints[0].measures ?? [],
          {
            dataType: DataTypes.TIMEANDATTENDANCEMONTHLY,
            dataField: TimeAndAttendanceMonthlyDataFields.NUM_OVERTIME_HOURS,
          },
          Operations.MIN
        )
      ) || 0;
    const max: number =
      Math.ceil(
        getValueForMeasure(
          result?.dataPoints[0].measures ?? [],
          {
            dataType: DataTypes.TIMEANDATTENDANCEMONTHLY,
            dataField: TimeAndAttendanceMonthlyDataFields.NUM_OVERTIME_HOURS,
          },
          Operations.MAX
        )
      ) ?? 0;
    this.overtimeSliderMax = max;
    this.overtimeSliderMin = min;
    this.overtimeSliderValue = [this.overtimeSliderMin, this.overtimeSliderMax];
  };

  @computed
  public get otRangeFilters(): ApiMasterDataQueryFilterItem[] {
    if (!this.overtimeSliderValue) return [];
    return [
      getOtHoursFilter(this.overtimeSliderValue[0], Operations.GREATER_THAN_OR_EQUAL_TO),
      getOtHoursFilter(this.overtimeSliderValue[1], Operations.LESS_THAN_OR_EQUAL_TO),
    ];
  }

  @observable
  public workHoursSliderMax: number | null = null;

  @observable
  public workHoursSliderMin: number | null = null;

  @observable
  public workHoursSliderValue: number[] | null = null;

  @observable
  public selectedOvertimePopulationType: OvertimePopulation = OvertimePopulation.ONLYWITHOVERTIME;
  @action
  public setSelectedOvertimePopulationType = (populationType: OvertimePopulation) =>
    (this.selectedOvertimePopulationType = populationType);

  @action
  public workHoursSliderOnChange = (val: number[]) => (this.workHoursSliderValue = val);

  @action
  public loadWorkHoursSliderRange = async () => {
    const query: ApiMasterDataAdvancedQuery = {
      dataType: DataTypes.TIMEANDATTENDANCEMONTHLY,
      measures: [
        {
          dataType: DataTypes.TIMEANDATTENDANCEMONTHLY,
          operation: Operations.MIN,
          property: TimeAndAttendanceMonthlyDataFields.NUM_HOURS_WORKED,
        },
        {
          dataType: DataTypes.TIMEANDATTENDANCEMONTHLY,
          operation: Operations.MAX,
          property: TimeAndAttendanceMonthlyDataFields.NUM_HOURS_WORKED,
        },
      ],
      filterItems: [
        getVersionFilter(
          rootStore.timeSliderStore.getOldestStartDate(),
          Operations.GREATER_THAN,
          DataTypes.TIMEANDATTENDANCEMONTHLY
        ),
      ],
    };
    const result = await GetResponseForAdvancedMasterQueryService(query);
    const min: number =
      Math.floor(
        getValueForMeasure(
          result?.dataPoints[0].measures ?? [],
          {
            dataType: DataTypes.TIMEANDATTENDANCEMONTHLY,
            dataField: TimeAndAttendanceMonthlyDataFields.NUM_HOURS_WORKED,
          },
          Operations.MIN
        )
      ) ?? 0;
    const max: number =
      Math.ceil(
        getValueForMeasure(
          result?.dataPoints[0].measures ?? [],
          {
            dataType: DataTypes.TIMEANDATTENDANCEMONTHLY,
            dataField: TimeAndAttendanceMonthlyDataFields.NUM_HOURS_WORKED,
          },
          Operations.MAX
        )
      ) ?? 0;
    this.workHoursSliderMin = min;
    this.workHoursSliderMax = max;
    this.workHoursSliderValue = [this.workHoursSliderMin, this.workHoursSliderMax];
  };

  @computed
  public get workHoursRangeFilters(): ApiMasterDataQueryFilterItem[] {
    if (!this.workHoursSliderValue) return [];
    return [
      {
        dataType: DataTypes.TIMEANDATTENDANCEMONTHLY,
        operation: Operations.GREATER_THAN_OR_EQUAL_TO,
        property: TimeAndAttendanceMonthlyDataFields.NUM_HOURS_WORKED,
        values: [this.workHoursSliderValue[0]],
      },
      {
        dataType: DataTypes.TIMEANDATTENDANCEMONTHLY,
        operation: Operations.LESS_THAN_OR_EQUAL_TO,
        property: TimeAndAttendanceMonthlyDataFields.NUM_HOURS_WORKED,
        values: [this.workHoursSliderValue[1]],
      },
    ];
  }

  @action
  public setWorkHoursFactBoxGranularity = (newGranularity: WorkHoursFactboxGranularity) => {
    this.selectedFactboxGranularity = newGranularity;
  };

  @action
  public setOvertimeFactboxValue = (newValue: OvertimeFactboxValues) => {
    this.selectedOvertimeFactboxValue = newValue;
  };

  @computed
  public get isHoursSelected() {
    return this.daysAndHoursToggle[DAYS_AND_HOURS.HOURS];
  }

  @computed
  public get isDaysSelected() {
    return this.daysAndHoursToggle[DAYS_AND_HOURS.DAYS];
  }
  @action
  public onChangeSelectedType = (value: string) => {
    if (value === DAYS_AND_HOURS.DAYS) {
      if (!this.daysAndHoursToggle[DAYS_AND_HOURS.HOURS]) {
        return;
      }
    } else if (value === DAYS_AND_HOURS.HOURS) {
      if (!this.daysAndHoursToggle[DAYS_AND_HOURS.DAYS]) {
        return;
      }
    }
    this.daysAndHoursToggle[value] = !this.daysAndHoursToggle[value];
  };
}

export const timeAndAttendanceStore = new TimeAndAttendanceStore();
