import { Chip, Divider, Grid, Paper } from '@mui/material';
import { ChipProps } from '@mui/material/Chip';
import { GridProps } from '@mui/material/Grid';
import React from 'react';
import styled from 'styled-components';

export const Flex = styled('div')`
  display: flex;
`;

export const Row = styled(Flex)`
  flex-flow: row nowrap;
`;

export const Column = styled(Flex)`
  flex-flow: column nowrap;
`;

export const Spacer = styled.div`
  flex: 1;
`;

export const FullHeightGrid = styled(Grid)`
  height: 100%;
`;

export const GridWithHeight = styled(Grid)<{
  height: string;
}>`
  height: ${(props) => props.height};
` as typeof Grid;

export const WhiteDivider = styled(Divider)`
  background: white;
`;

export const FullWidthPaper = styled(Paper)`
  width: 100%;
  height: 100%;
` as typeof Paper;

export const ColoredFullWidthPaper = styled(FullWidthPaper)`
  background-color: ${(props) => props.theme.color.primary};
` as typeof Paper;

export const FixedWidthGap = styled.div<{ gap?: string }>`
  width: ${(props) => props.gap || '4px'};
`;

export const FixedHeightGap = styled.div<{ gap?: string }>`
  height: ${(props) => props.gap || '4px'};
`;

export const DisabledBlock = styled('div')`
  background-color: rgb(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
`;
