import produce from 'immer';
import {
  ApiMasterDataAdvancedQuery,
  ApiMasterDataAdvancedQueryJoins,
  ApiMasterDataQueryFilterItem,
  JoinTypes,
} from '../../../../common/api/api-interfaces';
import {
  ApplicationDataFields,
  DataTypes,
  InterviewDataFields,
  JobDataFields,
  OfferDataFields,
  Operations,
} from '../../../../common/constants/constants';
import { getVersionFilter } from '../../../../common/filter/common-filters';
import { rootStore } from '../../../../common/store/root-store';

export const jobToOfferTableJoin: ApiMasterDataAdvancedQueryJoins = {
  joinType: JoinTypes.INNER,
  joinDataType: DataTypes.JOB,
  joinConditions: [
    {
      leftDataType: DataTypes.OFFER,
      leftProperty: OfferDataFields.JOB_ID,
      rightDataType: DataTypes.JOB,
      rightProperty: JobDataFields.JOB_ID,
      operation: Operations.EQUAL,
    },
  ],
};

export const offerToJobTableJoin: ApiMasterDataAdvancedQueryJoins = {
  joinType: JoinTypes.INNER,
  joinDataType: DataTypes.OFFER,
  joinConditions: [
    {
      leftDataType: DataTypes.OFFER,
      leftProperty: OfferDataFields.JOB_ID,
      rightDataType: DataTypes.JOB,
      rightProperty: JobDataFields.JOB_ID,
      operation: Operations.EQUAL,
    },
  ],
};

export const applicationToOfferTableJoin: ApiMasterDataAdvancedQueryJoins = {
  joinType: JoinTypes.INNER,
  joinDataType: DataTypes.APPLICATION,
  joinConditions: [
    {
      leftDataType: DataTypes.OFFER,
      leftProperty: OfferDataFields.APPLICATION_ID,
      rightDataType: DataTypes.APPLICATION,
      rightProperty: ApplicationDataFields.APPLICATION_ID,
      operation: Operations.EQUAL,
    },
  ],
};

export const offerToApplicationTableJoin: ApiMasterDataAdvancedQueryJoins = {
  joinType: JoinTypes.INNER,
  joinDataType: DataTypes.OFFER,
  joinConditions: [
    {
      leftDataType: DataTypes.OFFER,
      leftProperty: OfferDataFields.APPLICATION_ID,
      rightDataType: DataTypes.APPLICATION,
      rightProperty: ApplicationDataFields.APPLICATION_ID,
      operation: Operations.EQUAL,
    },
  ],
};

export const jobToApplicationTableJoin: ApiMasterDataAdvancedQueryJoins = {
  joinType: JoinTypes.INNER,
  joinDataType: DataTypes.JOB,
  joinConditions: [
    {
      leftDataType: DataTypes.APPLICATION,
      leftProperty: ApplicationDataFields.JOB_ID,
      rightDataType: DataTypes.JOB,
      rightProperty: JobDataFields.JOB_ID,
      operation: Operations.EQUAL,
    },
  ],
};

export const applicationToJobTableJoin: ApiMasterDataAdvancedQueryJoins = {
  joinType: JoinTypes.INNER,
  joinDataType: DataTypes.APPLICATION,
  joinConditions: [
    {
      leftDataType: DataTypes.JOB,
      leftProperty: JobDataFields.JOB_ID,
      rightDataType: DataTypes.APPLICATION,
      rightProperty: ApplicationDataFields.JOB_ID,
      operation: Operations.EQUAL,
    },
  ],
};

export const applicationToInterviewTableJoin: ApiMasterDataAdvancedQueryJoins = {
  joinType: JoinTypes.INNER,
  joinDataType: DataTypes.APPLICATION,
  joinConditions: [
    {
      leftDataType: DataTypes.INTERVIEW,
      leftProperty: InterviewDataFields.APPLICATION_ID,
      rightDataType: DataTypes.APPLICATION,
      rightProperty: ApplicationDataFields.APPLICATION_ID,
      operation: Operations.EQUAL,
    },
  ],
};

export const interviewToApplicationTableJoin: ApiMasterDataAdvancedQueryJoins = {
  joinType: JoinTypes.INNER,
  joinDataType: DataTypes.INTERVIEW,
  joinConditions: [
    {
      leftDataType: DataTypes.APPLICATION,
      leftProperty: ApplicationDataFields.APPLICATION_ID,
      rightDataType: DataTypes.INTERVIEW,
      rightProperty: InterviewDataFields.APPLICATION_ID,
      operation: Operations.EQUAL,
    },
  ],
};

export const offerToInterviewTableJoin: ApiMasterDataAdvancedQueryJoins = {
  joinType: JoinTypes.INNER,
  joinDataType: DataTypes.OFFER,
  joinConditions: [
    {
      leftDataType: DataTypes.INTERVIEW,
      leftProperty: InterviewDataFields.APPLICATION_ID,
      rightDataType: DataTypes.OFFER,
      rightProperty: OfferDataFields.APPLICATION_ID,
      operation: Operations.EQUAL,
    },
  ],
};

// export const jobToInterviewTableJoin: ApiMasterDataAdvancedQueryJoins = {
//   joinType: JoinTypes.INNER,
//   joinDataType: DataTypes.JOB,
//   joinConditions: [
//     {
//       leftDataType: DataTypes.INTERVIEW,
//       leftProperty: InterviewDataFields.JOB_ID,
//       rightDataType: DataTypes.JOB,
//       rightProperty: JobDataFields.JOB_ID,
//       operation: Operations.EQUAL,
//     },
//   ],
// };

export const queryHasVersionIdFilter = (query: ApiMasterDataAdvancedQuery) => {
  return (
    query.filterItems?.some((filter) => filter.dataType === query.dataType && filter.property === 'VERSION_ID') ?? false
  );
};

export const addJoinsToRecruitmentQuery = (query: ApiMasterDataAdvancedQuery) => {
  switch (query.dataType) {
    case DataTypes.APPLICATION:
    case DataTypes.OFFER:
    case DataTypes.JOB:
    case DataTypes.INTERVIEW:
      return addVersionIdFiltersToQuery(addJoinsToQuery(query));
    default:
      console.warn(
        `You are trying to add joins to an unsupported query data type.
        Please extend dataTypesToJoinMap and dataTypesToVersionIdFilterMap in addVersionIdFiltersToQuery to make it work.`
      );
      return query;
  }
};

const dataTypesToJoinMap: Partial<Record<DataTypes, Partial<Record<DataTypes, ApiMasterDataAdvancedQueryJoins[]>>>> = {
  [DataTypes.APPLICATION]: {
    [DataTypes.OFFER]: [offerToApplicationTableJoin],
    [DataTypes.JOB]: [jobToApplicationTableJoin],
  },
  [DataTypes.OFFER]: {
    [DataTypes.APPLICATION]: [applicationToOfferTableJoin],
    [DataTypes.JOB]: [jobToOfferTableJoin],
  },
  [DataTypes.JOB]: {
    [DataTypes.APPLICATION]: [applicationToJobTableJoin],
    [DataTypes.OFFER]: [offerToJobTableJoin],
  },
  [DataTypes.INTERVIEW]: {
    [DataTypes.APPLICATION]: [applicationToInterviewTableJoin],
    [DataTypes.OFFER]: [offerToInterviewTableJoin],
    [DataTypes.JOB]: [applicationToInterviewTableJoin, jobToApplicationTableJoin],
  },
};

const addJoinsToQuery = (query: ApiMasterDataAdvancedQuery) => {
  return produce(query, (draft) => {
    const dataType = query.dataType as DataTypes;
    const joinDataTypes = (query.joins?.map((j) => j.joinDataType) ?? []) as DataTypes[];
    const filtersDataTypes = (query.filterItems?.map((j) => j.dataType) ?? []) as DataTypes[];

    const dataTypesToJoin: DataTypes[] = filtersDataTypes.filter((d) => d !== dataType && !joinDataTypes.includes(d));
    const additionalJoins: ApiMasterDataAdvancedQueryJoins[] = dataTypesToJoin.flatMap((dataTypeToJoin) => {
      const joinsForDataType = dataTypesToJoinMap[dataType]?.[dataTypeToJoin];
      return joinsForDataType ?? [];
    });
    draft.joins = Array.from(new Set([...(query.joins ?? []), ...additionalJoins]));
  });
};

const addVersionIdFiltersToQuery = (query: ApiMasterDataAdvancedQuery) => {
  return produce(query, (draft) => {
    const filtersDataTypes = (query.joins?.map((j) => j.joinDataType) ?? []) as DataTypes[];
    const additionalFilters: ApiMasterDataQueryFilterItem[] = [
      ...(query.dataType ? [query.dataType] : []),
      ...filtersDataTypes,
    ].flatMap((filterDataType) => {
      const latestVersion = rootStore.domainDependencyStore.getLatestVersion(filterDataType);
      return latestVersion && filterDataType !== DataTypes.INTERVIEW
        ? [getVersionFilter(latestVersion, Operations.EQUAL, filterDataType)]
        : [];
    });
    draft.filterItems = [...(query.filterItems ?? []), ...Array.from(new Set([...additionalFilters]))];
  });
};
