import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  SnackbarContent,
  TextField,
} from '@mui/material';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

export interface ReportBugProps {
  open: boolean;
  onClose: () => void;
}

const StyledSnackBarContent = styled(SnackbarContent as any)<{
  background: string;
}>`
  && {
    background-color: ${(props) => props.background};
    & span {
      display: flex;
      align-items: center;
    }
  }
`;

@observer
export class ReportBug extends Component<ReportBugProps> {
  @observable
  private acknowledgementMessage = '';

  public render() {
    const { open, onClose } = this.props;
    return (
      <>
        <Dialog open={open} onClose={onClose}>
          <DialogTitle>
            <Trans i18nKey={'pages:reportBug.heading'} />
          </DialogTitle>
          <DialogContent>
            {/* <DialogContentText>
            <Typography variant="body1">
              Report your bug or security concern here.
            </Typography>
          </DialogContentText> */}
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Report Issue"
              // type="email"
              multiline
              rows="4"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              <Trans i18nKey={'pages:reportBug.cancelButton'} />
            </Button>
            <Button onClick={this.sendBugReport} color="primary">
              <Trans i18nKey={'pages:reportBug.submitButton'} />
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={Boolean(this.acknowledgementMessage)}
          autoHideDuration={6000}
          onClose={() => this.setAcknowledgementMessage('')}
        >
          <StyledSnackBarContent
            message={this.acknowledgementMessage}
            background="green"

            // action={[
            //   <IconButton
            //     key="close"
            //     aria-label="Close"
            //     color="inherit"
            //     onClick={reset}
            //   >
            //     <CloseIcon />
            //   </IconButton>
            // ]}
          />
        </Snackbar>
      </>
    );
  }

  @action
  private setAcknowledgementMessage(newMessage: string) {
    this.acknowledgementMessage = newMessage;
  }

  private sendBugReport = () => {
    // Send Report to the endpoint here
    window.setTimeout(() => {
      runInAction(() => this.setAcknowledgementMessage(''));
      this.props.onClose();
    }, 1000);
  };
}
