import { DataFieldWithDataType } from '../../../common-types';
import { MetricCategoryId, MetricFormatTypeId, MetricGroupId, MetricId } from '../graphql/generated/graphql-sdk';

export enum MetricLifecycleStageEnum {
  MetricLifecycleStageActive = 'MetricLifecycleStageActive',
  MetricLifecycleStageDeprecated = 'MetricLifecycleStageDeprecated',
  MetricLifecycleStageDevelopment = 'MetricLifecycleStageDevelopment',
  MetricLifecycleStageDisabled = 'MetricLifecycleStageDisabled',
}

export interface MetricDetails {
  id: MetricId;
  metricGroupId: MetricGroupId;
  isCohort: boolean;
  formatType: MetricFormatTypeId;
  underlyingFields: DataFieldWithDataType[] | null;
  defaultPosition: number;
}

export interface MetricGroupDetails {
  id: MetricGroupId;
  defaultCategory: MetricCategoryId;
  dimensions: MetricDetails[];
  defaultPosition: number;
  defaultCategoryPosition: number;
}
