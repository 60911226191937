import isEqual from 'lodash.isequal';
import partition from 'lodash.partition';
import React from 'react';
import {
  ApiMasterDataAdvancedQueryDimension,
  ApiMasterDataQueryFilterItem,
  ApiMasterDataQueryMeasure,
  ApiMasterDataTypes,
} from './app/common/api/api-interfaces';
import { DataFields, DataTypes } from './app/common/constants/constants';
import { trackError } from './app/common/sentry/sentry-no-rootstore';

export type ClickEvent<ElementType = HTMLInputElement> = React.MouseEvent<ElementType>;

export interface DataFieldWithDataType {
  dataType: DataTypes;
  dataField: DataFields;
}

export interface IndexRange {
  startIndex: number;
  stopIndex: number;
}

export const getKeyFromDataFieldWithDataType = (d: DataFieldWithDataType): string => {
  return `${d.dataType}+__+${d.dataField}`;
};

//TODO: instead of logging the error here, better throw an exception and catch it where relevant so that we can track and log properly
export const getDataFieldWithDataTypeFromKey = (d: string): DataFieldWithDataType => {
  const [dataType, dataField] = d.split('+__+');
  if (!dataType || !dataField) {
    const errorMessage = `Couldn't parse key containing datatype and datafield. Key: ${d}`;
    console.error(errorMessage);
    trackError(errorMessage);
  }
  return { dataType: dataType as DataTypes, dataField: dataField as DataFields };
};

export const sameKindFiltersSplit = (
  filters: ApiMasterDataQueryFilterItem[],
  dimension: DataFieldWithDataType
): [ApiMasterDataQueryFilterItem[], ApiMasterDataQueryFilterItem[]] => {
  return partition(filters, (filter: ApiMasterDataQueryFilterItem) => compareFilterToDim(filter, dimension));
};

export const compareFilterToDim = (filter: ApiMasterDataQueryFilterItem, dimension: DataFieldWithDataType) =>
  isEqual(filterToDataFieldWithDataType(filter), dimension);

export const compareDimensionToDim = (dim: ApiMasterDataAdvancedQueryDimension, dimension: DataFieldWithDataType) =>
  isEqual(dimensionToDataFieldWithDataType(dim), dimension);

export const compareMeasureToDim = (measure: ApiMasterDataQueryMeasure, dimension: DataFieldWithDataType) =>
  isEqual(measureToDataFieldWithDataType(measure), dimension);

export const toDataFieldWithDataType = (dataType: ApiMasterDataTypes | DataTypes, field: string) => ({
  dataType: dataType as DataTypes,
  dataField: field as DataFields,
});

export const dimensionToDataFieldWithDataType = (dimension: ApiMasterDataAdvancedQueryDimension) => ({
  dataType: dimension.dataType as DataTypes,
  dataField: dimension.property as DataFields,
});

export const filterToDataFieldWithDataType = (filter: ApiMasterDataQueryFilterItem) => ({
  dataType: filter.dataType as DataTypes,
  dataField: filter.property as DataFields,
});

const measureToDataFieldWithDataType = (measure: ApiMasterDataQueryMeasure) => ({
  dataType: measure.dataType as DataTypes,
  dataField: measure.property as DataFields,
});

export const toAdvancedDimension: (
  dataFieldWithDataType: DataFieldWithDataType
) => ApiMasterDataAdvancedQueryDimension = (dataFieldWithDataType: DataFieldWithDataType) => ({
  dataType: dataFieldWithDataType.dataType,
  property: dataFieldWithDataType.dataField,
});

export interface Constructable<T> {
  new (...args: any): T;
}
