import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import {
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Grid, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import { arrayMoveImmutable } from 'array-move';
import memoize from 'fast-memoize';
import isEqual from 'lodash.isequal';
import { IReactionDisposer, reaction, runInAction } from 'mobx';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  AutoSizer as _AutoSizer,
  Column as _Column,
  InfiniteLoader as _InfiniteLoader,
  Table as _Table,
  AutoSizerProps,
  ColumnProps,
  IndexRange,
  InfiniteLoaderProps,
  SortDirectionType,
  SortIndicator,
  TableCellDataGetterParams,
  TableProps,
} from 'react-virtualized';
import 'react-virtualized/styles.css';
import styled from 'styled-components';
import {
  compareDimensionToDim,
  DataFieldWithDataType,
  dimensionToDataFieldWithDataType,
  getDataFieldWithDataTypeFromKey,
  getKeyFromDataFieldWithDataType,
} from '../../../../common-types';
import {
  ApiMasterDataQueryResponseDataPoint,
  ApiMasterDataQueryResponseDataPointDimensionData,
  ApiMasterDataQuerySortOrder,
} from '../../api/api-interfaces';
import { EmployeeDataFields } from '../../constants/constants';
import { trackColumnsSort, trackDataViewSortBy } from '../../helpers/trackers/dashboardTracker';
import { rootStore } from '../../store/root-store';
import { materialUiDefaultBlack, primaryRowHoverColor } from '../../theme/default-theme';
import {
  formatApiMasterDataProperty,
  formatDataPoint,
  formatNumber,
  formatProperty,
  FormatTypes,
} from '../../utilFunctions/formatters';
import { sortObjectsBy, SortType } from '../../utilFunctions/sorters';
import { sum } from '../../utilFunctions/utils';
import { SortConfig } from '../dashboard/dashboard-data-view';
import { DEFAULT_LIMIT } from '../query/constants';
import i18n from './../../../../i18n';
import { panalytFieldNameSortOrder } from './FieldSortOrder';

// https://github.com/bvaughn/react-virtualized/issues/1739#issuecomment-1264276522
const AutoSizer = _AutoSizer as unknown as React.FC<AutoSizerProps>;
const InfiniteLoader = _InfiniteLoader as unknown as React.FC<InfiniteLoaderProps>;
const Column = _Column as unknown as React.FC<ColumnProps>;
const Table = _Table as unknown as React.FC<TableProps>;

interface ReportViewProps {
  dataPoints: ApiMasterDataQueryResponseDataPoint[];
  headers: DataFieldWithDataType[];
  loadMoreRows: (params: IndexRange) => Promise<any>;
  exportDataCallback: any;
  setSortConfig?: (newSortConfig: SortConfig) => void;
  sortConfig?: SortConfig;
  sortOrder?: DataFieldWithDataType[];
  sortableFieldsWhiteList?: DataFieldWithDataType[];
}

const StyledTable = styled(Table)`
  .row {
    cursor: pointer;
    padding-right: 0 !important;
  }
  & .row:nth-child(even) {
    background: rgba(25, 164, 153, 0.3); // Later need to get this from the theme
  }
  .row:hover {
    background-color: ${primaryRowHoverColor};
  }
  & .grid {
    outline: none; // Later need to get this from the theme
  }
  & .header {
    outline: none; // Later need to get this from the theme
  }
  & .header-row {
    text-transform: none;
  }
`;
const AlignedFlex = styled('div')`
  display: flex;
  height: 100%;
  align-items: center;
  text-transform: none;
  min-width: 110px;
`;
const HeaderTypography = styled(Typography)<{ $disabled?: boolean }>`
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  user-select: none;
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
`;
const SortableItemWrapper = styled('div')`
  &:first-of-type {
    margin-left: 10px;
  }
  margin-right: 10px;
  overflow: hidden;
  display: flex;
  height: 40px;
`;
// The styles for nested div are here because of the switch to dnd-kit library we don't use headerRenderer prop anymore
// So react-virtualized thinks that there is no header and hides it by setting height and width to 0
const TableWrapper = styled('div')<{ style: any }>`
  height: 100%;
  min-width: 95vw;
  position: relative;
  & > div {
    height: unset !important;
    width: unset !important;
  }
`;
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: materialUiDefaultBlack,
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}));

const getColumnWidthTable = memoize(
  (dataPoints: ApiMasterDataQueryResponseDataPoint[], headers: DataFieldWithDataType[]) => {
    const columnWidthTable: { [key: string]: number } = {};
    const minColumnWidthInPx = 110;
    if (dataPoints.length) {
      headers.forEach((d) => (columnWidthTable[d.dataField] = minColumnWidthInPx));
      dataPoints.forEach((data) => {
        data.dimensions.forEach((dim) => {
          if (dim && dim.value) {
            const maxWidth = Math.max(columnWidthTable[dim.property] || 0, String(dim.value).length * 11);
            columnWidthTable[dim.property] = Math.min(maxWidth, 300);
          }
        });
      });
    }
    return columnWidthTable;
  }
);

const sort = memoize(
  ({
    sortBy,
    sortDirection,
    dataPoints,
  }: {
    sortBy: DataFieldWithDataType;
    sortDirection: ApiMasterDataQuerySortOrder;
    dataPoints: ApiMasterDataQueryResponseDataPoint[];
  }) => {
    const sortByDimIndex = dataPoints[0]?.dimensions?.findIndex((dim) =>
      isEqual(dimensionToDataFieldWithDataType(dim), sortBy)
    );
    if (sortByDimIndex > -1) {
      const sortedDataPoints = sortObjectsBy({
        objs: dataPoints,
        valueGetter: (dp: ApiMasterDataQueryResponseDataPoint) => dp.dimensions[sortByDimIndex].value,
        baseDim: sortBy,
        sortType: sortDirection === ApiMasterDataQuerySortOrder.ASC ? SortType.asc : SortType.desc,
      });
      return sortedDataPoints;
    }
    return dataPoints;
  }
);

const sortHeaders = memoize((headers: DataFieldWithDataType[], sortOrder?: DataFieldWithDataType[]) => {
  return [...headers].sort((a, b) => {
    const order = sortOrder ?? panalytFieldNameSortOrder;
    const aIndex = order.findIndex((value: DataFieldWithDataType) => isEqual(value, a));
    const bIndex = order.findIndex((value: DataFieldWithDataType) => isEqual(value, b));
    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex > bIndex ? 1 : -1;
    } else if (aIndex === -1) {
      return 1;
    } else {
      return -1;
    }
  });
});

const cellRenderer = ({ cellData }: any): any => {
  return cellData ? <Typography title={cellData}>{cellData} </Typography> : '';
};

const HeaderRenderer = ({
  field,
  label,
  sortByField,
  sortDirection,
  sortableFieldsWhiteList,
}: {
  field: DataFieldWithDataType;
  label: string;
  sortByField: DataFieldWithDataType | undefined;
  sortDirection: string | undefined;
  sortableFieldsWhiteList?: DataFieldWithDataType[];
}) => {
  const showSortIndicator = isEqual(sortByField, field);
  const isSortingDisabled = !(sortableFieldsWhiteList?.deepCompareContains(field) ?? true);
  return (
    <AlignedFlex className="intercom_reportview_header">
      {isSortingDisabled ? (
        <LightTooltip title={<p>{i18n.t('pages:reports.notSortableColumn') as string}</p>}>
          <HeaderTypography variant="caption" key="label" $disabled={isSortingDisabled}>
            {isNaN(Number(label)) ? label : formatNumber(label, FormatTypes.COUNT)}
          </HeaderTypography>
        </LightTooltip>
      ) : (
        <HeaderTypography variant="caption" key="label" title={label} $disabled={isSortingDisabled}>
          {isNaN(Number(label)) ? label : formatNumber(label, FormatTypes.COUNT)}
        </HeaderTypography>
      )}
      {showSortIndicator && <SortIndicator key="SortIndicator" sortDirection={sortDirection as SortDirectionType} />}
    </AlignedFlex>
  );
};

interface SortableItemProps {
  key: string;
  id: string;
  isDragOverlay?: boolean;
  columnWidthTable: Record<string, number>;
  sortBy: DataFieldWithDataType;
  sortDirection: ApiMasterDataQuerySortOrder;
  sortableFieldsWhiteList?: DataFieldWithDataType[];
  setSortingCallback: (sortBy: string, sortDirection: ApiMasterDataQuerySortOrder) => void;
}

const SortableItem = (props: SortableItemProps) => {
  const { id, isDragOverlay, columnWidthTable, sortBy, sortDirection, sortableFieldsWhiteList, setSortingCallback } =
    props;
  const { attributes, listeners, setNodeRef, transform, isDragging, transition } = useSortable({
    id,
    transition: null,
  });
  const field = getDataFieldWithDataTypeFromKey(id);
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    flex: `0 1 ${columnWidthTable[field.dataField]}px`,
    // no idea why TS doesn't like a very usual CSS prop
    ['visibility' as any]: !isDragOverlay && isDragging ? 'hidden' : 'visible',
  };
  let newDirection = ApiMasterDataQuerySortOrder.ASC;
  if (isEqual(sortBy, field) && sortDirection === ApiMasterDataQuerySortOrder.ASC) {
    newDirection = ApiMasterDataQuerySortOrder.DESC;
  }

  return (
    <SortableItemWrapper
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
      {...props}
      onClick={() => setSortingCallback(id, newDirection)}
    >
      <HeaderRenderer
        field={field}
        label={formatApiMasterDataProperty({ property: field }, rootStore.aliasStore.getAliasTextForField(field))}
        sortByField={sortBy}
        sortDirection={sortDirection}
        sortableFieldsWhiteList={sortableFieldsWhiteList}
      />
    </SortableItemWrapper>
  );
};

const ReportView = (props: ReportViewProps) => {
  const infiniteLoaderRef: React.RefObject<_InfiniteLoader> = useRef(null);

  let reactionToCallbackDisposer: IReactionDisposer;

  const { dataPoints, sortConfig, headers, sortOrder, sortableFieldsWhiteList } = props;

  const [sortBy, setSortBy] = useState(props.headers[0]);
  const [sortDirection, setSortDirection] = useState(ApiMasterDataQuerySortOrder.ASC);
  const [sortedHeaders, setSortedHeaders] = useState<DataFieldWithDataType[]>(sortHeaders(headers, sortOrder));
  const [draggedItemId, setDraggedItemId] = useState<string | null>(null);

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 5 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor, { activationConstraint: { distance: 5 } })
  );

  useEffect(() => {
    reactionToCallbackDisposer = reaction(
      () => rootStore.exportStore.exportDataUpdatesCounter,
      () => props.exportDataCallback()
    );
    runInAction(() => {
      rootStore.reportStore.reportFieldsOrder = sortedHeaders;
    });

    return () => {
      reactionToCallbackDisposer();
      rootStore.reportStore.reportFieldsOrder = [];
    };
  }, [props.exportDataCallback]);

  useEffect(() => {
    const sortedHeaders = sortHeaders(headers, sortOrder);
    setSortedHeaders(sortedHeaders);
    runInAction(() => {
      rootStore.reportStore.reportFieldsOrder = sortedHeaders;
    });
  }, [props.headers]);

  const columnWidthTable = getColumnWidthTable(dataPoints, sortedHeaders);

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setDraggedItemId(event.active.id as string);
  }, []);

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over?.id) {
      setSortedHeaders((items) => {
        const oldIndex = items.findIndex((v) => isEqual(v, getDataFieldWithDataTypeFromKey(active.id as string)));
        const newIndex = items.findIndex((v) => isEqual(v, getDataFieldWithDataTypeFromKey(over.id as string)));

        return arrayMoveImmutable<DataFieldWithDataType>(items, oldIndex, newIndex);
      });
      trackColumnsSort();
    }
    setDraggedItemId(null);
  }, []);

  const formattedSortedDataPoints = sortConfig
    ? dataPoints.map(formatDataPoint)
    : sort({
        sortBy,
        sortDirection,
        dataPoints,
      }).map(formatDataPoint);

  const isRowLoaded = ({ index }: { index: number }): boolean => {
    return !!formattedSortedDataPoints[index];
  };

  const reportViewStyles = {
    width: sum(Object.values(columnWidthTable)),
  };

  const handleRowClick = useCallback((rowData: any) => {
    const employeeId =
      rowData?.rowData?.find(
        (r: ApiMasterDataQueryResponseDataPointDimensionData) => r.property === EmployeeDataFields.EMPLOYEE_ID
      )?.value ?? '';
    rootStore.employeeProfileStore.handleClick(employeeId);
  }, []);

  const setSorting = useCallback((sortBy: string, sortDirection: ApiMasterDataQuerySortOrder) => {
    const sortByField = getDataFieldWithDataTypeFromKey(sortBy);
    infiniteLoaderRef.current?.resetLoadMoreRowsCache();
    const { setSortConfig, sortableFieldsWhiteList } = props;

    if (sortableFieldsWhiteList) {
      if (sortableFieldsWhiteList.deepCompareContains(sortByField) && setSortConfig) {
        setSortConfig({ sortBy: sortByField, sortOrder: sortDirection });
      }
      return;
    }

    if (setSortConfig) {
      setSortConfig({ sortBy: sortByField, sortOrder: sortDirection });
    }
    setSortBy(sortByField);
    setSortDirection(sortDirection);
    trackDataViewSortBy(sortByField.dataField);
  }, []);

  const columns = useMemo(() => {
    return sortedHeaders.map((dim) => {
      return (
        <Column
          key={getKeyFromDataFieldWithDataType(dim)}
          label={formatProperty(dim.dataField, '_')}
          dataKey={getKeyFromDataFieldWithDataType(dim)}
          width={columnWidthTable[dim.dataField]}
          cellDataGetter={({ dataKey, rowData }: TableCellDataGetterParams) => {
            const field = getDataFieldWithDataTypeFromKey(dataKey);
            const data = rowData.find((d: any) => compareDimensionToDim(d, field))?.value ?? '';
            return data;
          }}
          cellRenderer={cellRenderer}
        />
      );
    });
  }, [sortedHeaders, columnWidthTable]);

  return (
    <TableWrapper style={reportViewStyles}>
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={props.loadMoreRows}
        rowCount={10000}
        ref={infiniteLoaderRef}
        minimumBatchSize={DEFAULT_LIMIT + 1} // for some reason, InfiniteLoader sets limit to this prop value minus one; this is to keep consistency
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ width: w, height }: { width: string | number; height: string | number }) => (
              <StyledTable
                width={Math.max(parseInt(String(w)), reportViewStyles.width)}
                height={Number(height)}
                headerHeight={40}
                rowHeight={30}
                rowCount={formattedSortedDataPoints.length}
                rowGetter={({ index }: { index: number }) => formattedSortedDataPoints[index].dimensions}
                rowClassName={({ index }: { index: number }) => (index >= 0 ? 'row' : '')}
                gridClassName="grid"
                sortBy={
                  sortConfig?.sortBy
                    ? getKeyFromDataFieldWithDataType(sortConfig?.sortBy)
                    : getKeyFromDataFieldWithDataType(sortBy)
                }
                sortDirection={sortConfig?.sortOrder ?? sortDirection}
                onRowsRendered={onRowsRendered}
                ref={registerChild}
                onRowClick={handleRowClick}
                headerRowRenderer={() => (
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    modifiers={[restrictToHorizontalAxis]}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                  >
                    <SortableContext items={sortedHeaders as any} strategy={horizontalListSortingStrategy}>
                      <Grid
                        container
                        wrap="nowrap"
                        sx={{
                          height: '40px',
                          overflow: 'hidden auto',
                          width: Math.max(parseInt(String(w)), reportViewStyles.width),
                        }}
                      >
                        {sortedHeaders.map((field) => {
                          const joinedField = getKeyFromDataFieldWithDataType(field);
                          return (
                            <SortableItem
                              key={joinedField}
                              id={joinedField}
                              columnWidthTable={columnWidthTable}
                              sortBy={sortConfig?.sortBy ?? sortBy}
                              sortDirection={sortConfig?.sortOrder ?? sortDirection}
                              sortableFieldsWhiteList={sortableFieldsWhiteList}
                              setSortingCallback={setSorting}
                            />
                          );
                        })}
                      </Grid>
                      {createPortal(
                        <DragOverlay>
                          {draggedItemId ? (
                            <SortableItem
                              key={draggedItemId}
                              id={draggedItemId}
                              isDragOverlay
                              columnWidthTable={columnWidthTable}
                              sortBy={sortConfig?.sortBy ?? sortBy}
                              sortDirection={sortConfig?.sortOrder ?? sortDirection}
                              sortableFieldsWhiteList={sortableFieldsWhiteList}
                              setSortingCallback={setSorting}
                            />
                          ) : null}
                        </DragOverlay>,
                        document.body
                      )}
                    </SortableContext>
                  </DndContext>
                )}
              >
                {columns}
              </StyledTable>
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    </TableWrapper>
  );
};

export default ReportView;
