import orderBy from 'lodash.orderby';
import { action, computed, observable } from 'mobx';
import { PerformanceCycle } from '../../../common/api/zod-schemas';
import { DataFields } from '../../../common/constants/constants';
import { rootStore } from '../../../common/store/root-store';
import { evaluationBaseColor } from '../../../common/theme/default-theme';
import { getNextGradientColor } from '../../../common/utilFunctions/utils';

export enum EvaluationConfigurationOptions {
  all = 'all',
  active = 'active',
  leavers = 'leavers',
}
class EvaluationStore {
  public maxCycleAmountToDisplay = 4;

  @observable
  public selectedEvaluationCycleType: string = '';

  @observable
  public selectedCycle: PerformanceCycle | null = null;

  @observable
  public evaluationRatingToColorMap: Record<string, string> = {};

  @observable
  public selectedPerformanceRatings: Record<string, boolean> = {};

  @observable
  public selectedEvaluationConfiguration: EvaluationConfigurationOptions = EvaluationConfigurationOptions.all;

  @observable
  public defaultsComputed = false;

  @observable
  public multiCycleEvaluationCycles: PerformanceCycle[] = [];

  @action
  public setSelectedEvaluationCycleType = (newValue: string) => {
    const cycles = this.availableCycles.filter((c) => c.cycleType === newValue);
    const firstCycle = cycles.first();
    if (!firstCycle) {
      return;
    }
    this.selectedEvaluationCycleType = newValue;
    this.setSelectedCycle(firstCycle.cycleName);
    this.setSelectedMultipleCycles(cycles.slice(0, this.maxCycleAmountToDisplay).map((cycle) => cycle.cycleName));
    this.setEvaluationScoreRatings();
  };

  @action
  public setSelectedCycle = (newValue: string) => {
    this.selectedCycle = this.getEvaluationCycleFromCycleTypeAndName(this.selectedEvaluationCycleType, newValue);
  };

  @action
  public setSelectedMultipleCycles = (newValues: string[]) => {
    this.multiCycleEvaluationCycles = newValues
      .map((value) => this.getEvaluationCycleFromCycleTypeAndName(this.selectedEvaluationCycleType, value))
      .sort((a, b) => new Date(b.cycleDate).getTime() - new Date(a.cycleDate).getTime());
  };

  public getEvaluationCycleFromCycleTypeAndName = (cycleType: string, cycleName: string) => {
    const evaluationCyclesInfo = rootStore.companySettingsStore.evaluationCyclesInfo();
    return evaluationCyclesInfo.find(
      (cycle: PerformanceCycle) => cycle.cycleType === cycleType && cycle.cycleName === cycleName
    ) as PerformanceCycle;
  };

  @action
  public setSelectedPerformanceRatings = (newValue: { [key: string]: boolean }) => {
    this.selectedPerformanceRatings = newValue;
  };

  @action
  public setSelectedEvaluationConfiguration = (newValue: EvaluationConfigurationOptions) => {
    this.selectedEvaluationConfiguration = newValue;
  };

  @action
  public toggleSelectedPerformanceRating = (key: string) => {
    if (key in this.selectedPerformanceRatings) {
      this.selectedPerformanceRatings[key] = !this.selectedPerformanceRatings[key];
    }
  };

  @action
  public computeDefaults = () => {
    const selectedEvaluationCycle = this.availableCycles.first();
    if (selectedEvaluationCycle) {
      this.setSelectedEvaluationCycleType(selectedEvaluationCycle.cycleType);
      this.setSelectedCycle(selectedEvaluationCycle.cycleName);
      const cyclesForDefaultType = this.availableCycles.filter(
        (c) => c.cycleType === selectedEvaluationCycle.cycleType
      );
      this.setSelectedMultipleCycles(
        cyclesForDefaultType.slice(0, this.maxCycleAmountToDisplay).map((cycle) => cycle.cycleName)
      );
      this.defaultsComputed = true;
    }
  };

  @action
  public setEvaluationRatingToColorMap(baseColor = evaluationBaseColor, maxGradientChange = 150) {
    let currentColorGradient = baseColor;
    const gradientChangeAmount = maxGradientChange / this.sortedPerformanceRatings.length;
    this.evaluationRatingToColorMap = Object.fromEntries(
      this.sortedPerformanceRatings.map((r) => {
        const nextGradientColor = getNextGradientColor(currentColorGradient, gradientChangeAmount);
        currentColorGradient = nextGradientColor;
        return [r, nextGradientColor];
      })
    );
  }

  @computed
  public get availableCycles(): PerformanceCycle[] {
    const evaluationCyclesInfo = rootStore.companySettingsStore.evaluationCyclesInfo();
    return orderBy(evaluationCyclesInfo, ['cycleDate', 'cycleType'], ['desc', 'asc']);
  }

  @computed
  public get performanceRatingsMap() {
    const performanceRatingsMap = this.selectedCycle?.evaluationValuesToScoreMap;
    return performanceRatingsMap ?? {};
  }

  @computed
  public get sortedPerformanceRatings() {
    const sortingArray: string[] = this.selectedCycle?.scoreSortingOrder ?? [];
    const sortedPerformanceRatingList = Object.keys(this.performanceRatingsMap).sort(
      (a: string, b: string) => sortingArray.indexOf(a as DataFields) - sortingArray.indexOf(b as DataFields)
    );
    return sortedPerformanceRatingList;
  }

  @computed
  public get selectedPerformanceScores() {
    const selectedRatings = Object.fromEntries(
      Object.entries(this.selectedPerformanceRatings).filter(([key, isSelected]) => isSelected)
    );
    return Object.keys(selectedRatings).map((rating) => this.performanceRatingsMap[rating]);
  }

  @action
  private setEvaluationScoreRatings = () => {
    // selectedPerformanceRatingsMap
    const selectedPerformanceRatings = Object.fromEntries(this.sortedPerformanceRatings.map((key) => [key, true]));
    this.setSelectedPerformanceRatings(selectedPerformanceRatings);

    this.setEvaluationRatingToColorMap();
  };
}

export const evaluationStore = new EvaluationStore();
