import React from 'react';
import { RouteProps } from 'react-router';
import { ROUTE_URLS } from '../constants/constants';
import { environmentService } from '../environment/environment-service';
import { rootStore } from '../store/root-store';
import { lazyWithRetry } from '../utilFunctions/pure-utils';

const Attrition = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../pages/dashboard/attrition/Attrition'));
const PeopleBalanceSheet = lazyWithRetry(
  () =>
    import(
      /* webpackPrefetch: true */ '../../pages/dashboard/balance-sheet/dashboards/people-balance-sheet/PeopleBalanceSheetDashboard'
    )
);

const RecruitmentBalanceSheet = lazyWithRetry(
  () =>
    import(
      /* webpackPrefetch: true */ '../../pages/dashboard/balance-sheet/dashboards/recruitment-balance-sheet/RecruitmentBalanceSheetDashboard'
    )
);
const Diversity = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../pages/dashboard/diversity/Diversity'));
const JoinersAndLeavers = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/dashboard/joiners-leavers/JoinersAndLeavers')
);
const MedianBase = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/dashboard/median-base/MedianBase')
);
const PeopleSnapshot = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/dashboard/people-snapshot/PeopleSnapshot')
);
const TimeAndAttendance = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/dashboard/time-and-attendance/TimeAndAttendance')
);
const SurveyDashboard = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/dashboard/survey-dashboard/SurveyDashboard')
);
const Recruitment = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/dashboard/recruitment/Recruitment')
);

const Evaluation = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/dashboard/evaluation/Evaluation')
);
const Movement = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../pages/dashboard/movements/Movements'));
const Payroll = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/dashboard/payroll-dashboard/Payroll')
);

const LoginPage = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../pages/login/login-page'));
const CrextaLoginPage = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../pages/login/crexta-login-page'));
const SamlLoginPage = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../pages/login/saml-login-page'));
const ForgotPasswordPage = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/login/forgot-password-page')
);
const ResetPasswordPage = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/reset-password/reset-password')
);
const VerifyEmail = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../pages/login/verify-email'));

const MyPanalytPage = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../pages/my-panalyt/my-panalyt-page'));
const Alias = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../pages/admin/alias/Alias'));
const UserPermissions = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/admin/user/permissions/UserPermissions')
);
const EditRolePermissions = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/admin/user/permissions/EditRolePermissions')
);
const DataUpload = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../pages/admin/data-upload/DataUpload'));
const CompanyList = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/panalyt-superadmin/company/CompanyList')
);
const ManageDomainSettings = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/panalyt-superadmin/company/ManageDomainSettings')
);
const ManageDomainPreferences = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/panalyt-superadmin/company/ManageDomainPreferences')
);
const CompanyDashboards = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/panalyt-superadmin/company/CompanyDashboards')
);
const ManageMetrics = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/panalyt-superadmin/company/ManageMetrics')
);
const MultiDomainAuth = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/panalyt-superadmin/company/MultiDomainAuth')
);
const BackendApi = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/panalyt-superadmin/company/BackendApi')
);
const MyAccount = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../pages/admin/my-account/MyAccount'));
const ErrorFallback = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../ErrorFallback'));
const ErrorFallbackCrexta = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../ErrorFallbackCrexta'));
const WrongAuthMethodError = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../WrongAuthMethodError'));
const NoAccountRegisteredError = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../NoAccountRegisteredError')
);
const DomainPreferences = lazyWithRetry(
  () =>
    import(/* webpackPrefetch: true */ '../../pages/panalyt-superadmin/company/domain-preferences/DomainPreferences')
);
const LoginErrorPage = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../LoginErrorPage'));

export interface DashboardRouteProps extends RouteProps {
  id: string;
  debug?: boolean;
  isEnabled?: () => boolean;
}

const domainsWithSamlLogin = ['valentinsaml.panalyt.com', 'jt.panalyt.com', 'jt2.panalyt.com'];

const getLoginPage = () => {
  if (domainsWithSamlLogin.some((domain) => window.location.origin.includes(domain))) {
    return SamlLoginPage;
  } else if (environmentService.isCrexta) {
    return CrextaLoginPage;
  } else {
    return LoginPage;
  }
};

export const publicRoutes: DashboardRouteProps[] = [
  {
    id: 'login',
    path: ROUTE_URLS.LOGIN,
    component: getLoginPage(),
  },
  {
    id: 'forgotpassword',
    path: ROUTE_URLS.FORGOT_PASSWORD,
    component: ForgotPasswordPage,
  },
  {
    id: 'reset',
    path: ROUTE_URLS.RESET,
    component: ResetPasswordPage,
  },
  {
    id: 'verifyEmail',
    path: ROUTE_URLS.VERIFY_EMAIL,
    component: VerifyEmail,
  },
  {
    id: 'samlError',
    path: ROUTE_URLS.LOGIN_ERROR,
    component: LoginErrorPage,
  },
  {
    id: 'setup-error',
    path: ROUTE_URLS.SETUP_ERROR,
    component: ErrorFallbackCrexta,
  },
  {
    id: 'wrong-auth-method-error',
    path: ROUTE_URLS.WRONG_AUTH_METHOD_ERROR,
    component: WrongAuthMethodError,
  },
  {
    id: 'no-account-error',
    path: ROUTE_URLS.NO_ACCOUNT_ERROR,
    component: NoAccountRegisteredError,
  },
];

export const dataUploadRoute = {
  id: 'adminDataUpload',
  path: ROUTE_URLS.DATA_UPLOAD,
  component: DataUpload,
  isEnabled: () => rootStore.permissionsStore.canUploadData(),
};

export const pageRoutes: DashboardRouteProps[] = [
  {
    id: 'myPanalyt',
    path: ROUTE_URLS.MY_PANALYT,
    component: MyPanalytPage,
  },
  {
    id: 'adminAlias',
    path: ROUTE_URLS.ALIAS,
    component: Alias,
  },
  {
    id: 'userPermissions',
    path: ROUTE_URLS.PERMISSIONS,
    component: UserPermissions,
    isEnabled: () => rootStore.featureAccessService.canViewUserPermissions(),
  },
  {
    id: 'editRolePermissions',
    path: ROUTE_URLS.EDIT_ROLE_PERMISSIONS,
    component: () => <EditRolePermissions isReadonly={false} />,
    isEnabled: () => rootStore.featureAccessService.canWriteRoleSettings(),
  },
  {
    id: 'viewRolePermissions',
    path: ROUTE_URLS.VIEW_ROLE_PERMISSIONS,
    // just adding another url to the same page for readonly view of permissions
    component: () => <EditRolePermissions isReadonly={true} />,
    isEnabled: () => rootStore.featureAccessService.canViewRoleSettings(),
  },
  dataUploadRoute,
  {
    id: 'panalytSuperadminCompany',
    path: ROUTE_URLS.COMPANY_LIST,
    component: CompanyList,
  },
  {
    id: 'domainPreferencesAdminPage',
    path: ROUTE_URLS.DOMAIN_PREFERENCES,
    component: DomainPreferences,
    isEnabled: () => rootStore.enabledFeaturesService.isDomainPreferencesEnabled(),
  },
  {
    id: 'panalytSuperadminManageDomainSettings',
    path: `${ROUTE_URLS.MANAGE_DOMAIN_SETTINGS}/:domain?`,
    component: ManageDomainSettings,
  },
  {
    id: 'panalytSuperadminManageDomainPreferences',
    path: `${ROUTE_URLS.MANAGE_DOMAIN_PREFERENCES}/:domain?`,
    component: ManageDomainPreferences,
  },
  {
    id: 'panalytSuperadminManageCompanyDashboards',
    path: `${ROUTE_URLS.COMPANY_DASHBOARDS}/:domain?`,
    component: CompanyDashboards,
  },
  {
    id: 'panalytSuperadminManageMetrics',
    path: `${ROUTE_URLS.MANAGE_METRICS}/:domain?`,
    component: ManageMetrics,
  },
  {
    id: 'panalytSuperadminMultiDomainAuth',
    path: `${ROUTE_URLS.MULTI_DOMAIN_AUTH}/:domain?`,
    component: MultiDomainAuth,
  },
  {
    id: 'panalytSuperadminBackendApi',
    path: `${ROUTE_URLS.BACKEND_API}/:domain?`,
    component: BackendApi,
  },
  {
    id: 'adminMyAccount',
    path: ROUTE_URLS.MY_ACCOUNT,
    component: MyAccount,
  },
  {
    id: 'somethingWentWrong',
    path: ROUTE_URLS.SOMETHING_WENT_WRONG,
    component: ErrorFallback,
  },
];

export const dashboardRoutes: DashboardRouteProps[] = [
  {
    id: 'peopleSnapshot',
    path: `${ROUTE_URLS.PEOPLE_SNAPSHOT}/:pageNumber?`,
    component: PeopleSnapshot,
  },
  {
    id: 'genderDiversity',
    path: `${ROUTE_URLS.DIVERSITY_GENDER}/:pageNumber?`,
    component: Diversity,
  },
  {
    id: 'attrition',
    path: `${ROUTE_URLS.ATTRITION}/:pageNumber?`,
    component: Attrition,
  },
  {
    id: 'joinersAndLeavers',
    path: `${ROUTE_URLS.JOINERS_AND_LEAVERS}/:pageNumber?`,
    component: JoinersAndLeavers,
  },
  {
    id: 'medianBase',
    path: `${ROUTE_URLS.MEDIAN_BASE}/:pageNumber?`,
    component: MedianBase,
  },
  {
    id: 'recruitment',
    path: `${ROUTE_URLS.RECRUITMENT}/:pageNumber?`,
    component: Recruitment,
  },
  {
    id: 'survey',
    path: `${ROUTE_URLS.SURVEY}/:pageNumber?`,
    component: SurveyDashboard,
  },
  {
    id: 'evaluation',
    path: `${ROUTE_URLS.EVALUATION}/:pageNumber?`,
    component: Evaluation,
    debug: true,
  },
  {
    id: 'movement',
    path: `${ROUTE_URLS.MOVEMENT}/:pageNumber?`,
    component: Movement,
  },
  {
    id: 'payroll',
    path: `${ROUTE_URLS.PAYROLL}/:pageNumber?`,
    component: Payroll,
    debug: true,
  },
  {
    id: 'workHoursAndOt',
    path: `${ROUTE_URLS.WORK_HOURS_AND_OT}/:pageNumber?`,
    component: TimeAndAttendance,
  },
  {
    id: 'peopleBalanceSheet',
    path: `${ROUTE_URLS.PEOPLE_BALANCE_SHEET}/:pageNumber?`,
    component: PeopleBalanceSheet,
    debug: true,
  },
  {
    id: 'recruitmentBalanceSheet',
    path: `${ROUTE_URLS.RECRUITMENT_BALANCE_SHEET}/:pageNumber?`,
    component: RecruitmentBalanceSheet,
    debug: true,
  },
];
