import { observer } from 'mobx-react';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { DashboardRouteProps } from '../../routes';
import { rootStore } from '../../store/root-store';
import LoadingOverlay from '../utils/LoadingOverlay';

@observer
export class PrivateRoute extends React.Component<DashboardRouteProps> {
  public render() {
    const { component: PathComponent, ...rest } = this.props;
    const { isLoggedIn, loading, emailNeedsVerification } = rootStore.authStore;
    return (
      <Route
        {...rest}
        // tslint:disable-next-line:jsx-no-lambda
        render={(props) => {
          if (loading) {
            return <LoadingOverlay />;
          }
          return isLoggedIn && !emailNeedsVerification && PathComponent ? (
            <PathComponent {...props} />
          ) : isLoggedIn && emailNeedsVerification ? (
            <Redirect
              to={{
                pathname: '/verify-email',
                state: { from: (props as any).location },
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: (props as any).location },
              }}
            />
          );
        }}
      />
    );
  }
}
