import produce from 'immer';
import isEqual from 'lodash.isequal';
import uniqWith from 'lodash.uniqwith';
import { observer } from 'mobx-react';
import React, { PureComponent } from 'react';
import { IndexRange } from '../../../../common-types';
import { ApiMasterDataQueryFilterItem } from '../../api/api-interfaces';
import { appCache } from '../../cache/cache';
import { getServiceCacheKey, Service } from '../../services/utils';
import { rootStore } from '../../store/root-store';
import { yieldToMain } from '../../utilFunctions/utils';
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from './constants';

interface RenderCallback<Response> {
  data: Response | null;
  refetch: (offsetParams?: IndexRange) => Promise<void>;
}

interface ServiceExecutorProps<Inputs, Response> {
  children: (state: RenderCallback<Response>) => React.ReactNode;
  service: Service<Inputs, Response>;
  inputs: Inputs;
  filters: ApiMasterDataQueryFilterItem[];
  withOffset?: boolean;
}

interface ServiceExecutorState {
  data: any;
}

// Observer is needed here if the callback component uses any observable
@observer
export default class ServiceExecutor<Inputs = any, Response = any> extends PureComponent<
  ServiceExecutorProps<Inputs, Response>,
  ServiceExecutorState
> {
  constructor(props: ServiceExecutorProps<Inputs, Response>) {
    super(props);

    this.state = { data: null };
  }

  public componentDidMount = async () => {
    await this.fetch();
  };

  public render() {
    const { children } = this.props;

    return (
      <>
        {children({
          data: this.state.data,
          refetch: this.fetch,
        })}
      </>
    );
  }

  public componentDidUpdate = (prevProps: ServiceExecutorProps<Inputs, Response>) => {
    if (
      getServiceCacheKey(prevProps.service, prevProps.inputs, prevProps.filters) !==
      getServiceCacheKey(this.props.service, this.props.inputs, this.props.filters)
    ) {
      this.fetch();
    }

    if (!this.props.withOffset && prevProps.withOffset) {
      this.setState({ data: null });
      this.fetch();
    }
  };

  private fetch = async (offsetParams?: IndexRange) => {
    try {
      const { filters, service, withOffset } = this.props;
      const freshDataFetch = !offsetParams && withOffset;
      const inputs = produce(this.props.inputs, (draft) => {
        if (withOffset) {
          (draft as any).offset = offsetParams?.startIndex ?? DEFAULT_OFFSET;
          (draft as any).limit = offsetParams ? offsetParams?.stopIndex - offsetParams?.startIndex : DEFAULT_LIMIT;
        }
      });
      rootStore.loadingStateStore.loadStarted();
      const cacheKey = getServiceCacheKey(service, inputs, filters);
      const response = await appCache.getFromCacheOrRequest(cacheKey, async () => {
        // This allows to split different ServiceExecutor operations into separate smaller tasks
        await yieldToMain();
        return service(inputs, filters);
      });

      let updatedData: any;
      if (withOffset) {
        if (Array.isArray(response)) {
          updatedData = freshDataFetch ? response : uniqWith([...(this.state.data ?? []), ...response], isEqual);
        } else if (typeof response === 'object' && response !== null) {
          if (freshDataFetch) {
            updatedData = response;
          } else {
            updatedData = {};
            Object.keys(response).forEach((key) => {
              if (key === 'recordsTotal') {
                updatedData[key] = updatedData[key] || (response as any)[key];
              } else if (key === 'recordsLoaded') {
                updatedData[key] = this.state.data?.[key] + (response as any)[key];
              } else {
                updatedData[key] = uniqWith([...(this.state.data?.[key] ?? []), ...(response as any)[key]], isEqual);
              }
            });
          }
        }
      } else {
        updatedData = response;
      }
      this.setState({ data: updatedData });
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error(err);
    } finally {
      rootStore.loadingStateStore.loadFinished();
    }
  };
}
