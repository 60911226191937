import { PERIOD_TOTAL } from '../../pages/dashboard/balance-sheet/types';
import { UserRolePreferences } from '../api/zod-schemas';
import { environmentService } from '../environment/environment-service';
import { LanguageStore } from '../localization/language-store';
import { publicRoutes } from '../routes';
import { formatUserBasedOnDisplayNameAndLocale } from '../utilFunctions/pure-utils';

export enum IntercomArticleKeys {
  FILTER_TRAY = 'FILTER_TRAY',
  DATA_VIEW = 'DATA_VIEW',
  BENCHMARK = 'BENCHMARK',
  FORECAST = 'FORECAST',
  SEGMENT = 'SEGMENT',
  EXPORT = 'EXPORT',
}
export class IntercomStore {
  private languageStore: LanguageStore;

  private defaultIntercomArticleid = 5971932;

  private intercomKeyToArticleIdMap = {
    [PERIOD_TOTAL]: 6826931,
    [IntercomArticleKeys.FILTER_TRAY]: 5685680,
    [IntercomArticleKeys.DATA_VIEW]: 5685717,
    [IntercomArticleKeys.BENCHMARK]: 6169046,
    [IntercomArticleKeys.FORECAST]: 6961743,
    [IntercomArticleKeys.SEGMENT]: 6147673,
    [IntercomArticleKeys.EXPORT]: 8026031,
  };

  private panalytIntercomKey = 'qcxorkyv';

  constructor(languageStore: LanguageStore) {
    this.languageStore = languageStore;
  }

  public getCorrespondingArticleIdFromIntercomKey = (intercomKey: string): number => {
    return (
      this.intercomKeyToArticleIdMap[intercomKey as keyof typeof this.intercomKeyToArticleIdMap] ??
      this.defaultIntercomArticleid
    );
  };

  public showArticleInIntercom = (intercomKey: string) => {
    window.Intercom('showArticle', this.getCorrespondingArticleIdFromIntercomKey(intercomKey));
  };

  public applyWindowIntercomSettings = (userRolePreferences: UserRolePreferences | null, email: string | null) => {
    if (window.intercomSettings && email) {
      const { selectedLanguage } = this.languageStore;
      const environmentName = environmentService.getEnvironment().name;
      const profile = userRolePreferences?.settings?.profile;

      const userId = `${email} - ${environmentName}`;
      this.updateSettings({
        name: profile ? formatUserBasedOnDisplayNameAndLocale(profile, selectedLanguage.id) : '',
        email,
        user_id: userId,
      });
    } else {
      console.debug(
        `Couldn't apply Intercom settings (window.intercomSettings, userRolePreferences, email)`,
        window.intercomSettings,
        JSON.parse(JSON.stringify(userRolePreferences)),
        email
      );
    }
  };

  public updateSettings = (settings: Intercom_.IntercomSettings): void => {
    window.intercomSettings = {
      ...window.intercomSettings,
      ...settings,
    };
    if (window.Intercom) {
      void window.Intercom('update', window.intercomSettings);
    } else {
      // @ts-ignore
      console.warn(`Couldn't update Intercom settings as Intercom wasn't initialized`);
    }
  };

  private isPublicRoute = (): boolean => {
    return publicRoutes.some((route) => route.path?.includes(window.location.pathname));
  };
}
