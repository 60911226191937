import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { materialUiDefaultBlack } from '../../theme/default-theme';

const setClipboardToCopyText = (copyText: string) => {
  const type = 'text/plain';
  const blob = new Blob([copyText], { type });
  const data = [new ClipboardItem({ [type]: blob })];
  navigator.clipboard.write(data);
};

interface CopyButtonProps {
  color?: string;
  copyText: string;
}

export const CopyButton = (props: CopyButtonProps) => {
  const { color = materialUiDefaultBlack, copyText } = props;
  const [showCopyFeedback, setShowCopyFeedback] = useState(false);
  const handleClick = () => {
    setClipboardToCopyText(copyText);
    setShowCopyFeedback(true);
    setTimeout(() => setShowCopyFeedback(false), 2000);
  };
  return (
    <Tooltip open={showCopyFeedback} title="Copied!" arrow>
      <IconButton sx={{ color }} onClick={handleClick}>
        <ContentCopyIcon />
      </IconButton>
    </Tooltip>
  );
};
