import { ApiMasterDataAdvancedQuery, ApiMasterDataQueryResponse } from '../api/api-interfaces';
import { appCache, ICache } from '../cache/cache';
import { rootStore } from '../store/root-store';

interface Service {
  executeAdvancedQuery(domain: string, query: ApiMasterDataAdvancedQuery): Promise<ApiMasterDataQueryResponse>;
}

export interface GetResponseForAdvancedQueryServiceOptions {
  loadInBackground: boolean;
}

export const GetResponseForAdvancedMasterQueryService = async (
  query: ApiMasterDataAdvancedQuery,
  options?: GetResponseForAdvancedQueryServiceOptions
): Promise<ApiMasterDataQueryResponse> => {
  return GetResponseForAdvancedQueryService(query, appCache, rootStore.masterDataApiService, options);
};

const GetResponseForAdvancedQueryService = async (
  query: ApiMasterDataAdvancedQuery,
  cache: ICache,
  service: Service,
  options?: GetResponseForAdvancedQueryServiceOptions
) => {
  if (options?.loadInBackground === false) {
    rootStore.loadingStateStore.loadStarted();
  }
  try {
    const cacheKey = JSON.stringify({ query });
    const data = await cache.getFromCacheOrRequest(cacheKey, () => {
      return service.executeAdvancedQuery(rootStore.companyStore.domain, query);
    });
    return data;
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e);
    throw e;
  } finally {
    if (options?.loadInBackground === false) {
      rootStore.loadingStateStore.loadFinished();
    }
  }
};
