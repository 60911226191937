import { observable } from 'mobx';

class LocalStore {
  @observable
  public storesReInited = false;

  public readonly STORES_TO_REINIT = ['filterStore', 'recruitmentStore', 'segmentationStore', 'benchmarkStore'];

  /** add item to storage */
  public set(key: string, item: string): void {
    localStorage.setItem(key, item);
  }

  /**
   *  get one item by key from storage
   */
  public get(key: string): string | null {
    return localStorage.getItem(key);
  }
  /** remove value from storage */

  public remove(key: string): void {
    localStorage.removeItem(key);
  }

  public clear() {
    localStorage.clear();
  }

  public getAsync(name: string): Promise<string | null> {
    return new Promise((resolve) => {
      const data = localStorage.getItem(name);
      resolve(data);
    });
  }

  public setAsync(key: string, item: string): Promise<void> {
    return new Promise<void>((resolve) => {
      localStorage.setItem(key, item);
      resolve();
    });
  }

  public removeSavedStores = (): void => {
    this.STORES_TO_REINIT.forEach((storeName: string) => this.remove(storeName));
  };
}

export const localStore = new LocalStore();
