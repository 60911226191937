import { DataFieldWithDataType } from '../../../common-types';
import { ApiMasterDataQuery } from '../api/api-interfaces';
import { BenchmarkConfig } from '../api/master-data-service';
import { DataTypes } from '../constants/constants';
import { UndefinedValue } from '../constants/systemValuesTranslation';
import { ALL_TEMPORALITY_FILTER } from '../filter/common-filters';
import { TimeSliderConfig } from '../filter/filter-store';
import { GetResponseForMasterQueryService } from './GetResponseForQueryService';

interface GetDimensionValuesServiceInputs {
  dimension: DataFieldWithDataType;
  timeSliderConfig?: TimeSliderConfig;
  benchmarkConfig?: BenchmarkConfig;
  dataType?: DataTypes;
}

export const GetDimensionValuesService = async (inputs: GetDimensionValuesServiceInputs) => {
  const { dimension, benchmarkConfig, dataType = DataTypes.EMPLOYEE } = inputs;
  const limitedToPermittedPopulation =
    benchmarkConfig && benchmarkConfig.limitedToPermittedPopulation === false ? false : true;
  const getDimensionValuesQuery: ApiMasterDataQuery = {
    dimensions: [dimension.dataField],
    limitedToPermittedPopulation,
    dataType,
  };
  getDimensionValuesQuery.filterItems = dataType === DataTypes.EMPLOYEE ? [ALL_TEMPORALITY_FILTER] : [];
  const response = await GetResponseForMasterQueryService(getDimensionValuesQuery, benchmarkConfig);

  const dimensionValues: string[] = ((response && response.dataPoints) || []).map(
    (dp) => dp.dimensions[0].value || UndefinedValue
  );
  return dimensionValues;
};
