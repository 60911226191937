import { ManagerOrIcValues } from '../../constants/constant-backend-values';

interface ReportingLineSqlOptions {
  managerId: string | undefined;
  onlyManagers: boolean;
  domain: string;
  versionId: string;
}

export const getReportingLineSql = ({
  managerId,
  onlyManagers,
  domain,
  versionId,
}: ReportingLineSqlOptions): string => {
  let querySql: string;

  if (managerId && managerId !== 'root') {
    if (onlyManagers) {
      querySql = `
        with recursive managers as (
          select
              employee.employeeid,
              coalesce(employee.localfullname, employee.fullname, '') as fullname,
              employee.managerid,
              employee.manageroric,
              1 as level,
              cast(employee.employeeid as text) as path
          from
              employee
          where
              employee.namespace = '${domain}'
              and employee.versionid = '${versionId}'
              and employee.employeeid = '${managerId}'
          union all
          select
              employee.employeeid,
              coalesce(employee.localfullname, employee.fullname, '') as fullname,
              employee.managerid,
              employee.manageroric,
              managers.level + 1 as level,
              concat(managers.path, '.', employee.employeeid) as path
          from
              employee
          join managers on
              employee.managerid = managers.employeeid
              and employee.namespace = '${domain}'
              and employee.versionid = '${versionId}'
              and managers.level < 100
          )
          select
              managers.employeeid,
              managers.fullname,
              managers.managerid,
              managers.manageroric,
              managers.level,
              managers.path,
              (
              select
                  count(distinct managers.employeeid)
              from
                  managers
              where
                  managers.manageroric = '${ManagerOrIcValues.Manager}'
              and
                managers.managerid = managers.employeeid) as subordinatesCount
          from
              managers
          where
              managers.manageroric = '${ManagerOrIcValues.Manager}'
          order by
              managers.managerid;
        `;
    } else {
      querySql = `
      with recursive managers as (
        select
            employee.employeeid,
            coalesce(employee.localfullname, employee.fullname, '') as fullname,
            employee.managerid,
            employee.manageroric,
            1 as level,
            cast(employee.employeeid as text) as path
        from
            employee
        where
            employee.namespace = '${domain}'
            and employee.versionid = '${versionId}'
            and employee.employeeid = '${managerId}'
        union all
        select
            employee.employeeid,
            coalesce(employee.localfullname, employee.fullname, '') as fullname,
            employee.managerid,
            employee.manageroric,
            managers.level + 1 as level,
            concat(managers.path, '.', employee.employeeid) as path
        from
            employee
        join managers on
            employee.managerid = managers.employeeid
            and employee.namespace = '${domain}'
            and employee.versionid = '${versionId}'
            and managers.level < 100
        )
        select
            managers.employeeid,
            managers.fullname,
            managers.managerid,
            managers.manageroric,
            managers.level,
            managers.path,
            (
            select
                count(distinct managers.employeeid)
            from
                managers
            where
              managers.managerid = managers.employeeid) as subordinatesCount
        from
            managers
        order by
            managers.managerid;
  `;
    }
  } else {
    if (onlyManagers) {
      querySql = `
      with recursive roots as (
        select
            employee.employeeid
        from
            employee
        where
            employee.namespace = '${domain}'
            and employee.versionid = '${versionId}'
            and employee.managerid is null),
        managers as (
        select
            employee.employeeid,
            coalesce(employee.localfullname, employee.fullname, '') as fullname,
            employee.managerid,
            employee.manageroric,
            1 as level,
            cast(employee.employeeid as text) as path
        from
            employee
        join roots on
            employee.employeeid = roots.employeeid
        where
            employee.namespace = '${domain}'
            and employee.versionid = '${versionId}'
        union all
        select
            employee.employeeid,
            coalesce(employee.localfullname, employee.fullname, '') as fullname,
            employee.managerid,
            employee.manageroric,
            managers.level + 1 as level,
            concat(managers.path, '.', employee.employeeid) as path
        from
            employee
        join managers on
            employee.managerid = managers.employeeid
            and employee.namespace = '${domain}'
            and employee.versionid = '${versionId}'
            and managers.level < 100
        )
        select
            managers.employeeid,
            managers.fullname,
            managers.managerid,
            managers.manageroric,
            managers.level,
            managers.path,
            (
            select
                count(distinct managers.employeeid)
            from
                managers
            where
                managers.manageroric = '${ManagerOrIcValues.Manager}'
            and
              managers.managerid = managers.employeeid) as subordinatesCount
        from
            managers
        where
            managers.manageroric = '${ManagerOrIcValues.Manager}'
        order by
            managers.managerid;
      `;
    } else {
      querySql = `
      with recursive roots as (
        select
            employee.employeeid
        from
            employee
        where
            employee.namespace = '${domain}'
            and employee.versionid = '${versionId}'
            and employee.managerid is null),
        managers as (
        select
            employee.employeeid,
            coalesce(employee.localfullname, employee.fullname, '') as fullname,
            employee.managerid,
            employee.manageroric,
            1 as level,
            cast(employee.employeeid as text) as path
        from
            employee
        join roots on
            employee.employeeid = roots.employeeid
        where
            employee.namespace = '${domain}'
            and employee.versionid = '${versionId}'
        union all
        select
            employee.employeeid,
            coalesce(employee.localfullname, employee.fullname, '') as fullname,
            employee.managerid,
            employee.manageroric,
            managers.level + 1 as level,
            concat(managers.path, '.', employee.employeeid) as path
        from
            employee
        join managers on
            employee.managerid = managers.employeeid
            and employee.namespace = '${domain}'
            and employee.versionid = '${versionId}'
            and managers.level < 100
        )
        select
            managers.employeeid,
            managers.fullname,
            managers.managerid,
            managers.manageroric,
            managers.level,
            managers.path,
            (
            select
                count(distinct managers.employeeid)
            from
                managers
            and
              managers.managerid = managers.employeeid) as subordinatesCount
        from
            managers
        order by
            managers.managerid;
      `;
    }
  }
  return querySql;
};
