import {
  AddRoleMutation,
  AddRoleMutationVariables,
  AddUserAndDefaultRoleMutation,
  AddUserAndDefaultRoleMutationVariables,
  AddUserAndDefaultRoleWithRoleAndPermissionsMutation,
  AddUserAndDefaultRoleWithRoleAndPermissionsMutationVariables,
  ChangeRoleNameMutation,
  ChangeRoleNameMutationVariables,
  DeleteRoleAndPermissionsMutation,
  DeleteRoleAndPermissionsMutationVariables,
  GetMultiDomainAuthInfoQuery,
  GrantAssumeRoleMutation,
  GrantAssumeRoleMutationVariables,
  GrantExportReportsMutation,
  GrantExportReportsMutationVariables,
  GrantLoginMutation,
  GrantLoginMutationVariables,
  GrantReadDataMutation,
  GrantReadDataMutationVariables,
  GrantUseFiltersMutation,
  GrantUseFiltersMutationVariables,
  GrantViewDashboardsMutation,
  GrantViewDashboardsMutationVariables,
  GrantViewMetricsMutation,
  GrantViewMetricsMutationVariables,
  ListExecutorRolesForRoleQuery,
  ListExecutorRolesForRoleQueryVariables,
  MetaDataByUserRoleQuery,
  ReplaceLoginMutation,
  ReplaceLoginMutationVariables,
  ReplaceReadDataMutation,
  ReplaceReadDataMutationVariables,
  ReplaceUseFiltersMutation,
  ReplaceUseFiltersMutationVariables,
  ReplaceViewDashboardsMutation,
  ReplaceViewDashboardsMutationVariables,
  ReplaceViewMetricsMutation,
  ReplaceViewMetricsMutationVariables,
  RevokeAssignedPermissionMutation,
} from '../graphql/generated/graphql-sdk';
import { AssignedPermission, AssignedPermissions, Permission, Role, RoleId } from '../permissions/permissions';
import { rootStore } from '../store/root-store';
import { GraphQlRequestService } from './graphql-request-service';
import { getGraphqlResult } from './utils';

export interface PermissionService {
  listRoles(email: string): Promise<Role[]>;
  listAllRoles(): Promise<Role[]>;
  listUserRoles(domain: string): Promise<Role[]>;
  listNonUserRoles(domain: string): Promise<Role[]>;
  getMultiDomainAuthInfo(): Promise<GetMultiDomainAuthInfoQuery>;
  getAllPermissionsForCurrentUser(): Promise<AssignedPermission<Permission>[]>;
  getAllPermissionsForRole(roleId: RoleId): Promise<AssignedPermission<Permission>[]>;
  getDirectPermissionsForRole(roleId: string): Promise<AssignedPermission<Permission>[]>;
  getDirectPermissionsForRoles(roleIds: string[]): Promise<AssignedPermissions<Permission>[]>;
  revokeAssignedPermission(permissionId: string): Promise<RevokeAssignedPermissionMutation>;
  grantAssumeRole(variables: GrantAssumeRoleMutationVariables): Promise<GrantAssumeRoleMutation>;
  grantLogin(variables: GrantLoginMutationVariables): Promise<GrantLoginMutation>;
  grantReadData(variables: GrantReadDataMutationVariables): Promise<GrantReadDataMutation>;
  grantViewDashboards(variables: GrantViewDashboardsMutationVariables): Promise<GrantViewDashboardsMutation>;
  grantExportReports(variables: GrantExportReportsMutationVariables): Promise<GrantExportReportsMutation>;
  grantUseFilters(variables: GrantUseFiltersMutationVariables): Promise<GrantUseFiltersMutation>;
  grantViewMetrics(variables: GrantViewMetricsMutationVariables): Promise<GrantViewMetricsMutation>;
  replaceReadData(variables: Omit<ReplaceReadDataMutationVariables, 'simulateRole'>): Promise<ReplaceReadDataMutation>;
  replaceLogin(variables: Omit<ReplaceLoginMutationVariables, 'simulateRole'>): Promise<ReplaceLoginMutation>;
  replaceViewDashboards(
    variables: Omit<ReplaceViewDashboardsMutationVariables, 'simulateRole'>
  ): Promise<ReplaceViewDashboardsMutation>;
  replaceUseFilters(
    variables: Omit<ReplaceUseFiltersMutationVariables, 'simulateRole'>
  ): Promise<ReplaceUseFiltersMutation>;
  replaceViewMetrics(
    variables: Omit<ReplaceViewMetricsMutationVariables, 'simulateRole'>
  ): Promise<ReplaceViewMetricsMutation>;
  addRole(variables: AddRoleMutationVariables): Promise<AddRoleMutation>;
  addUserAndDefaultRole(variables: AddUserAndDefaultRoleMutationVariables): Promise<AddUserAndDefaultRoleMutation>;
  addUserAndDefaultRoleWithRoleAndPermissions(
    variables: AddUserAndDefaultRoleWithRoleAndPermissionsMutationVariables
  ): Promise<AddUserAndDefaultRoleWithRoleAndPermissionsMutation>;
  deleteRoleAndPermissions(
    variables: Omit<DeleteRoleAndPermissionsMutationVariables, 'simulateRole'>
  ): Promise<DeleteRoleAndPermissionsMutation>;
  changeRoleName(variables: ChangeRoleNameMutationVariables): Promise<ChangeRoleNameMutation>;
  listExecutorRolesForRole(
    variables: ListExecutorRolesForRoleQueryVariables,
    domain: string
  ): Promise<ListExecutorRolesForRoleQuery>;
  metaDataByUserRole(roleIds: string[]): Promise<MetaDataByUserRoleQuery>;
}

export class DefaultPermissionService implements PermissionService {
  graphQlRequestService: GraphQlRequestService;

  constructor(graphqlRequestService: GraphQlRequestService) {
    this.graphQlRequestService = graphqlRequestService;
  }

  public listAllRoles = async () => {
    return getGraphqlResult((await this.graphQlRequestService.graphQlSdk.listAllRoles()).listAllRoles);
  };

  public listUserRoles = async (domain: string) => {
    // simulateRole is null here intentionally as the simulated role might not have access to this endpoint
    return getGraphqlResult(
      (await this.graphQlRequestService.graphQlSdk.listUserRoles({ domainFilter: [domain], simulateRole: null }))
        .listUserRoles
    );
  };

  public listNonUserRoles = async (domain: string) => {
    // simulateRole is null here intentionally as the simulated role might not have access to this endpoint
    return getGraphqlResult(
      (await this.graphQlRequestService.graphQlSdk.listNonUserRoles({ domain: [domain], simulateRole: null }))
        .listNonUserRoles
    );
  };

  // This is used to get the assumeRole roles of a role
  public listRoles = async (email: string) => {
    // simulateRole is null here intentionally as the simulated role might not have access to this endpoint
    return getGraphqlResult((await this.graphQlRequestService.graphQlSdk.listRoles({ email })).listRoles);
  };

  public getAllPermissionsForCurrentUser = async () => {
    return getGraphqlResult(
      (await this.graphQlRequestService.graphQlSdk.currentUserAllPermissions()).currentUserAllPermissions
    );
  };

  public getAllPermissionsForRole = async (roleId: string) => {
    return getGraphqlResult((await this.graphQlRequestService.graphQlSdk.allPermissions({ roleId })).allPermissions);
  };

  public getDirectPermissionsForRole = async (roleId: string) => {
    return getGraphqlResult(
      (
        await this.graphQlRequestService.graphQlSdk.directPermissions({
          roleId: roleId,
        })
      ).directPermissions
    );
  };

  public getDirectPermissionsForRoles = async (roleIds: string[]) => {
    return getGraphqlResult(
      (
        await this.graphQlRequestService.graphQlSdk.directPermissionsForRoles({
          roleIds: roleIds,
        })
      ).directPermissionsForRoles
    ).map((entry) => new AssignedPermissions<Permission>(entry.permissions));
  };

  public revokeAssignedPermission = async (permissionId: string) => {
    return await this.graphQlRequestService.graphQlSdk.revokeAssignedPermission({ id: permissionId });
  };

  public grantAssumeRole = async ({ roleId, domain, roles, excludedRoles = [] }: GrantAssumeRoleMutationVariables) => {
    return await this.graphQlRequestService.graphQlSdk.grantAssumeRole({
      roleId,
      domain,
      roles,
      excludedRoles,
    });
  };

  public grantLogin = async ({ roleId, domain }: GrantLoginMutationVariables) => {
    return await this.graphQlRequestService.graphQlSdk.grantLogin({
      roleId,
      domain,
    });
  };

  public listExecutorRolesForRole = async (
    { roleId, simulateRole }: ListExecutorRolesForRoleQueryVariables,
    domain: string
  ) => {
    return await this.graphQlRequestService.graphQlSdk.listExecutorRolesForRole({
      roleId,
      domainFilter: domain,
      simulateRole,
    });
  };

  public getMultiDomainAuthInfo = async () => {
    const simulateRole = rootStore.permissionsStore.getSimulateRoleId();
    return await this.graphQlRequestService.graphQlSdk.getMultiDomainAuthInfo({
      simulateRole,
    });
  };

  public grantReadData = async ({
    roleId,
    domain,
    access,
    lineHierarchyRestrictions,
    popFilter,
  }: GrantReadDataMutationVariables) => {
    return await this.graphQlRequestService.graphQlSdk.grantReadData({
      roleId,
      domain,
      access,
      lineHierarchyRestrictions,
      popFilter,
    });
  };

  public grantViewDashboards = async ({
    roleId,
    domain,
    dashboardPages,
    exportChartView,
    exportDataView,
    viewDataView,
  }: GrantViewDashboardsMutationVariables) => {
    return await this.graphQlRequestService.graphQlSdk.grantViewDashboards({
      roleId,
      domain,
      dashboardPages,
      exportChartView,
      exportDataView,
      viewDataView,
    });
  };

  public grantExportReports = async ({ roleId, domain }: GrantExportReportsMutationVariables) => {
    return await this.graphQlRequestService.graphQlSdk.grantExportReports({
      roleId,
      domain,
    });
  };

  public grantUseFilters = async ({ roleId, domain, allowedTags }: GrantUseFiltersMutationVariables) => {
    return await this.graphQlRequestService.graphQlSdk.grantUseFilters({
      roleId,
      domain,
      allowedTags,
    });
  };

  public grantViewMetrics = async ({ roleId, allowedTags, domain }: GrantUseFiltersMutationVariables) => {
    return await this.graphQlRequestService.graphQlSdk.grantViewMetrics({
      domain,
      roleId,
      allowedTags,
    });
  };

  public replaceLogin = async (replaceLoginVariables: Omit<ReplaceLoginMutationVariables, 'simulateRole'>) => {
    const simulateRole = rootStore.permissionsStore.getSimulateRoleId();
    return await this.graphQlRequestService.graphQlSdk.replaceLogin({
      ...replaceLoginVariables,
      simulateRole,
    });
  };

  public replaceReadData = async (replaceReadDataVariables: Omit<ReplaceReadDataMutationVariables, 'simulateRole'>) => {
    const simulateRole = rootStore.permissionsStore.getSimulateRoleId();
    return await this.graphQlRequestService.graphQlSdk.replaceReadData({
      ...replaceReadDataVariables,
      simulateRole,
    });
  };

  public replaceViewDashboards = async (
    replaceViewDashboardVariables: Omit<ReplaceViewDashboardsMutationVariables, 'simulateRole'>
  ) => {
    const simulateRole = rootStore.permissionsStore.getSimulateRoleId();
    return await this.graphQlRequestService.graphQlSdk.replaceViewDashboards({
      ...replaceViewDashboardVariables,
      simulateRole,
    });
  };

  public replaceUseFilters = async (
    replaceUseFiltersVariables: Omit<ReplaceUseFiltersMutationVariables, 'simulateRole'>
  ) => {
    const simulateRole = rootStore.permissionsStore.getSimulateRoleId();
    return await this.graphQlRequestService.graphQlSdk.replaceUseFilters({
      ...replaceUseFiltersVariables,
      simulateRole,
    });
  };

  public replaceViewMetrics = async (
    replaceViewMetricsVariables: Omit<ReplaceViewMetricsMutationVariables, 'simulateRole'>
  ) => {
    const simulateRole = rootStore.permissionsStore.getSimulateRoleId();
    return await this.graphQlRequestService.graphQlSdk.replaceViewMetrics({
      ...replaceViewMetricsVariables,
      simulateRole,
    });
  };

  public addRole = async ({ name, domain }: AddRoleMutationVariables) => {
    return await this.graphQlRequestService.graphQlSdk.addRole({
      name,
      domain,
    });
  };

  public addUserAndDefaultRole = async ({ email, roleDomain }: AddUserAndDefaultRoleMutationVariables) => {
    return await this.graphQlRequestService.graphQlSdk.addUserAndDefaultRole({
      email,
      roleDomain,
    });
  };

  public addUserAndDefaultRoleWithRoleAndPermissions = async ({
    email,
    roleDomain,
  }: AddUserAndDefaultRoleWithRoleAndPermissionsMutationVariables) => {
    return await this.graphQlRequestService.graphQlSdk.addUserAndDefaultRoleWithRoleAndPermissions({
      email,
      roleDomain,
    });
  };

  public changeRoleName = async ({ id, newName, simulateRole }: ChangeRoleNameMutationVariables) => {
    return await this.graphQlRequestService.graphQlSdk.changeRoleName({
      id,
      newName,
      simulateRole,
    });
  };

  public deleteRoleAndPermissions = async (
    variables: Omit<DeleteRoleAndPermissionsMutationVariables, 'simulateRole'>
  ) => {
    const simulateRole = rootStore.permissionsStore.getSimulateRoleId();
    return await this.graphQlRequestService.graphQlSdk.deleteRoleAndPermissions({ ...variables, simulateRole });
  };

  public metaDataByUserRole = async (roleIds: string[]) => {
    return await this.graphQlRequestService.graphQlSdk.metaDataByUserRole({
      roleIds: roleIds,
      simulateRole: rootStore.permissionsStore.getSimulateRoleId(),
    });
  };
}
