import produce from 'immer';
import isEqual from 'lodash.isequal';
import uniqWith from 'lodash.uniqwith';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { match } from 'ts-pattern';
import { IndexRange } from '../../../../common-types';
import {
  ApiDataQueryType,
  ApiDataQueryWithTypeInfo,
  ApiMasterDataQueryInfiniteLoadingResponse,
  ApiMasterDataQueryResponse,
} from '../../api/api-interfaces';
import { BenchmarkConfig } from '../../api/employee-data-service/employee-data-service';
import { GetResponseForMovementQueryService } from '../../services/GetResponseForMovementQueryService';
import {
  GetResponseForAdvancedQueryService,
  GetResponseForMasterQueryService,
} from '../../services/GetResponseForQueryService';
import { rootStore } from '../../store/root-store';
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from './constants';

export interface RenderCallback {
  data: ApiMasterDataQueryResponse | ApiMasterDataQueryInfiniteLoadingResponse | null;
  refetch: (offsetParams?: IndexRange) => Promise<void>;
}

interface QueryExecutorProps {
  children: (state: RenderCallback) => React.ReactNode;
  onReady?: () => void;
  onLoading?: () => void;
  queryConfig: QueryConfig;
  benchmarkConfig?: BenchmarkConfig;
  withOffset?: boolean;
}

interface QueryExecutorState {
  data: ApiMasterDataQueryResponse | null;
  requestCounter: number;
}

interface QueryConfig {
  queriesWithType: ApiDataQueryWithTypeInfo[];
  resultsFormatter?: (responses: ApiMasterDataQueryResponse[]) => ApiMasterDataQueryResponse;
}

// Observer is needed here if the callback component uses any observable
@observer
export default class QueryExecutor extends Component<QueryExecutorProps, QueryExecutorState> {
  constructor(props: QueryExecutorProps) {
    super(props);

    this.state = { data: null, requestCounter: 0 };
  }

  public componentDidMount = async () => {
    this.fetch();
  };

  public render() {
    const { children } = this.props;

    return (
      <>
        {children({
          data: this.state.data,
          refetch: this.fetch,
        })}
      </>
    );
  }

  public componentDidUpdate = (prevProps: QueryExecutorProps) => {
    if (
      JSON.stringify({
        query: prevProps.queryConfig,
        benchmarkConfig: prevProps.benchmarkConfig,
      }) !==
      JSON.stringify({
        query: this.props.queryConfig,
        benchmarkConfig: this.props.benchmarkConfig,
      })
    ) {
      this.fetch();
    }

    if (!this.props.withOffset && prevProps.withOffset) {
      this.setState({ data: null });
      this.fetch();
    }
  };

  private defaultResultsFormatter = (responses: ApiMasterDataQueryResponse[]) => {
    const dataPoints = responses.flatMap((r) => r.dataPoints?.filter((x) => x) ?? []);
    return { dataPoints };
  };

  private fetch = async (offsetParams?: IndexRange) => {
    this.setState({ requestCounter: this.state.requestCounter + 1 });
    const currRequestNum = this.state.requestCounter + 1;
    const { benchmarkConfig, withOffset, onLoading, onReady } = this.props;
    const freshDataFetch = !offsetParams && withOffset;
    const queryConfig = produce(this.props.queryConfig, (draft) => {
      draft.queriesWithType.forEach((typedQuery) => {
        if (withOffset) {
          typedQuery.query.offset = offsetParams?.startIndex ?? DEFAULT_OFFSET;
          typedQuery.query.limit = offsetParams ? offsetParams?.stopIndex - offsetParams?.startIndex : DEFAULT_LIMIT;
        }
      });
    });

    try {
      onLoading && onLoading();
      rootStore.loadingStateStore.loadStarted();

      const responses: ApiMasterDataQueryResponse[] = await Promise.all(
        queryConfig.queriesWithType.map(async (queryWithType) => {
          return match(queryWithType)
            .with({ type: ApiDataQueryType.ApiMasterDataQuery }, (qWithType) =>
              GetResponseForMasterQueryService(qWithType.query, benchmarkConfig)
            )
            .with({ type: ApiDataQueryType.ApiMasterDataAdvancedQuery }, (qWithType) =>
              GetResponseForAdvancedQueryService(qWithType.query, benchmarkConfig)
            )
            .with({ type: ApiDataQueryType.ApiMasterDataMovementQuery }, (qWithType) =>
              GetResponseForMovementQueryService(qWithType.query, benchmarkConfig)
            )
            .exhaustive();
        })
      );

      const response: ApiMasterDataQueryResponse = queryConfig.resultsFormatter
        ? queryConfig.resultsFormatter(responses)
        : this.defaultResultsFormatter(responses);

      const isLatestRequest = currRequestNum === this.state.requestCounter;
      if (isLatestRequest) {
        let updatedData;
        if (withOffset) {
          updatedData = {
            dataPoints: freshDataFetch
              ? response.dataPoints
              : uniqWith([...(this.state.data?.dataPoints ?? []), ...response.dataPoints], isEqual),
          };
        } else {
          updatedData = response.dataPoints ? response : { dataPoints: [] };
        }
        this.setState({ data: updatedData });
      }
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error(err);
    } finally {
      rootStore.loadingStateStore.loadFinished();
      onReady && onReady();
    }
  };
}
