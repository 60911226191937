import { Avatar, IconButton, ListItemAvatar, ListItemText, MenuItem, MenuList, Typography } from '@mui/material';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Languages } from '../../constants/constants';
import { rootStore } from '../../store/root-store';
import { styled } from '../styled';
import Loading from '../utils/Loading';
import PopUpMenu from '../utils/PopUpMenu';

const StyledIconButton = styled(IconButton)`
  padding: 5px !important;
` as typeof IconButton;

const StyledAvatar = styled(Avatar)`
  height: 20px;
  width: 20px;
  background: transparent;
  border-radius: 0;
  font-size: 12px;
` as typeof Avatar;

@observer
export default class LanguageSwitcher extends Component {
  @observable
  private anchorEl: HTMLElement | null = null;

  @observable
  private open: boolean = false;

  private handleOpen = action(() => (this.open = true));

  private handleClose = action(() => (this.open = false));

  private handleProfileMenuOpen = action((event: React.MouseEvent<HTMLElement>) => {
    this.anchorEl = event.currentTarget;
    this.handleOpen();
  });

  public render() {
    const { languages, selectedLanguage } = rootStore.languageStore;
    return (
      <>
        <StyledIconButton
          aria-owns={this.anchorEl ? 'material-appbar' : undefined}
          aria-haspopup="true"
          onClick={this.handleProfileMenuOpen}
          color="inherit"
          className={'intercom_appbar_locale'}
        >
          <StyledAvatar>
            <Typography variant="body2">{selectedLanguage.shortName} </Typography>
          </StyledAvatar>
        </StyledIconButton>
        <PopUpMenu anchorEl={this.anchorEl} open={this.open} onClose={this.handleClose} placement="bottom-end">
          {languages.length > 1 ? (
            <MenuList>
              {languages.map(({ id, shortName, longName }) => (
                <MenuItem onClick={this.onLanguageSelect(id)} key={id} selected={selectedLanguage.id === id}>
                  <ListItemAvatar>
                    <StyledAvatar>{shortName}</StyledAvatar>
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{
                      variant: 'body2',
                      noWrap: true,
                    }}
                  >
                    {longName}
                  </ListItemText>
                </MenuItem>
              ))}
            </MenuList>
          ) : (
            <Loading />
          )}
        </PopUpMenu>
      </>
    );
  }
  @action
  private onLanguageSelect = (id: Languages) => () => {
    rootStore.languageStore.setLanguage(id);
    rootStore.intercomStore.updateSettings({ language_override: id });
    location.reload();
    this.handleClose();
  };
}
