import groupBy from 'lodash.groupby';
import { DataFieldWithDataType } from '../../../../common-types';
import { appCache, ICache } from '../../cache/cache';
import {
  CONFIDENTIAL_VALUE,
  DataFields,
  DataTypes,
  Domains,
  EmployeeDataFields,
  Operations,
} from '../../constants/constants';
import { getVersionFilter, PAST_PRESENT_TEMPORALITY_FILTER } from '../../filter/common-filters';
import { TimeSliderConfig } from '../../filter/filter-store';
import { trackAPIError } from '../../sentry/sentry';
import { rootStore } from '../../store/root-store';
import { isHierarchical, removeDuplicates } from '../../utilFunctions/utils';
import {
  ApiEmployeeSearchResponse,
  ApiEmployeeSearchResponseItem,
  ApiGetManagerHierarchy,
  ApiGetManagerResponse,
  ApiGetManagerResponseSubordinateItem,
  ApiHierarchyItem,
  ApiHierarchyItemList,
  ApiMasterDataAdvancedQuery,
  ApiMasterDataQueryFilterItem,
  ApiMasterDataQueryResponse,
} from '../api-interfaces';
import { getReportingLineSql } from '../api-sql.ts/reporting-line-sql';
import { parseCustomSqlValue } from '../graphql-utils';
import { cachedMasterDataApiService } from '../master-data-service';
import { getDataHierarchy } from './employee-data-service-utils';

export interface BenchmarkConfig {
  prduId?: string | null;
  pdu?: boolean;
  limitedToPermittedPopulation?: boolean;
}

export type TimeSliderConfigStartAndEnd = Pick<TimeSliderConfig, 'startDate' | 'endDate'>;

class EmployeeDataApiService {
  private cache: ICache;

  private maxHierarchyLevel = 10;

  constructor(cache: ICache = appCache) {
    this.cache = cache;
  }

  /**
   * Be aware that we currently only get the first 10 hierarchy levels
   */
  public getHierarchy = async (
    domain: Domains,
    dataFieldWithDataType: DataFieldWithDataType,
    timeConfig: TimeSliderConfigStartAndEnd | null
  ): Promise<ApiHierarchyItemList> => {
    const { startDate, endDate: versionId } = timeConfig ?? { startDate: null, endDate: null };
    try {
      if (isHierarchical(dataFieldWithDataType)) {
        let versionIdFilter;
        if (versionId) {
          versionIdFilter = getVersionFilter(versionId, Operations.EQUAL, dataFieldWithDataType.dataType);
        } else {
          const latestVersion =
            rootStore.domainDependencyStore.getLatestVersion(dataFieldWithDataType.dataType) ??
            (() => {
              throw new Error(`No latest version id for ${dataFieldWithDataType.dataType}`);
            })();

          versionIdFilter = getVersionFilter(latestVersion, Operations.EQUAL, dataFieldWithDataType.dataType);
        }

        const query: ApiMasterDataAdvancedQuery = {
          dataType: dataFieldWithDataType.dataType,
          measures: [],
          dimensions: Array(this.maxHierarchyLevel)
            .fill(0)
            .map((_, index) => {
              return {
                dataType: dataFieldWithDataType.dataType,
                property: `${dataFieldWithDataType.dataField}_LEVEL_${index + 1}` as DataFields,
              };
            }),
          filterItems: [versionIdFilter],
        };

        if (dataFieldWithDataType.dataType === DataTypes.EMPLOYEE && startDate) {
          const termDateFilter: ApiMasterDataQueryFilterItem = {
            dataType: DataTypes.EMPLOYEE,
            property: EmployeeDataFields.TERM_DATE_NORMALIZED,
            operation: Operations.GREATER_THAN_OR_EQUAL_TO,
            values: [startDate],
          };
          query.filterItems?.push(PAST_PRESENT_TEMPORALITY_FILTER, termDateFilter);
        }

        // Without await import, whole pgsql-ast-library gets shipped into the entrypoint chunk which is not desirable
        const QueryToASTConverter = (await import('../query-to-sql-converter')).default;

        // Converting to SQL queries here, because they implement a different (better) way in calculating the population count
        // This way works for non-employee datatypes as well, as long as the requesting role is e.g. superadmin. It will NOT work with population restrictions.
        const converter = new QueryToASTConverter(query);
        const sql = converter.querytoSql();
        const result: ApiMasterDataQueryResponse = await cachedMasterDataApiService.executeCustomSqlQuery(
          query.dimensions ?? [],
          query.measures ?? [],
          sql
        );

        const levelsOfEntities = removeDuplicates(result.dataPoints.map((db) => db.dimensions.map((d) => d.value)));
        /**
         * at this point levels of entities looks something like
         * [
         * [Design, TechDesign, Confidential]
         * [Design, FinDesign, Confidential]
         * [Marketing, DigitalMarketing, Confidential]
         * ]
         */
        const nonConfidentialLevelsOfEntities = levelsOfEntities.map((row) => {
          const confidentialIndex = row.indexOf(CONFIDENTIAL_VALUE);
          const nonConfidentalRow = confidentialIndex === -1 ? row : row.slice(0, confidentialIndex);
          // i.e. we remove everything from the hierarchy after the first confidential value
          return nonConfidentalRow;
        });
        const tree: ApiHierarchyItem[] = getDataHierarchy(nonConfidentialLevelsOfEntities);
        return { items: tree };
      } else {
        throw new Error(
          `Cannot get hierarchy for non-hierarchical ${dataFieldWithDataType.dataField} ${dataFieldWithDataType.dataType}`
        );
      }
    } catch (e) {
      console.error(e);
      throw new Error(
        `Cannot get hierarchy for non-hierarchical ${dataFieldWithDataType.dataField} ${dataFieldWithDataType.dataType}: ${e}`
      );
    }
  };

  public listEmploymentTypes = async (
    domain: Domains,
    timeConfig?: TimeSliderConfigStartAndEnd
  ): Promise<ApiHierarchyItemList> => {
    const r = await this.getHierarchy(
      domain,
      { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYMENT_TYPE },
      timeConfig ?? null
    );
    return r;
  };

  public listJobGrades = async (
    domain: Domains,
    timeConfig?: TimeSliderConfigStartAndEnd
  ): Promise<ApiHierarchyItemList> => {
    return this.getHierarchy(
      domain,
      { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOB_GRADE },
      timeConfig ?? null
    );
  };

  public listLocations = async (
    domain: Domains,
    timeConfig?: TimeSliderConfigStartAndEnd
  ): Promise<ApiHierarchyItemList> => {
    return this.getHierarchy(
      domain,
      { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION },
      timeConfig ?? null
    );
  };

  public listFunctions = async (
    domain: Domains,
    timeConfig?: TimeSliderConfigStartAndEnd
  ): Promise<ApiHierarchyItemList> => {
    return this.getHierarchy(
      domain,
      { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.FUNCTION },
      timeConfig ?? null
    );
  };

  public listOrganizations = async (
    domain: Domains,
    timeConfig?: TimeSliderConfigStartAndEnd
  ): Promise<ApiHierarchyItemList> => {
    return this.getHierarchy(
      domain,
      { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION },
      timeConfig ?? null
    );
  };

  public listPositions = async (
    domain: Domains,
    timeConfig?: TimeSliderConfigStartAndEnd
  ): Promise<ApiHierarchyItemList> => {
    return this.getHierarchy(
      domain,
      { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.POSITION },
      timeConfig ?? null
    );
  };

  public listNationalities = async (
    domain: Domains,
    timeConfig?: TimeSliderConfigStartAndEnd
  ): Promise<ApiHierarchyItemList> => {
    return this.getHierarchy(
      domain,
      { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.NATIONALITY_HIERARCHICAL },
      timeConfig ?? null
    );
  };

  public listRecruitmentCategories = async (
    domain: Domains,
    timeConfig?: TimeSliderConfigStartAndEnd
  ): Promise<ApiHierarchyItemList> => {
    return this.getHierarchy(
      domain,
      { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.RECRUITMENT_CATEGORY_HIERARCHICAL },
      timeConfig ?? null
    );
  };

  public async searchForEmployees(
    domain: string,
    searchInput: string,
    presentOnly: boolean
  ): Promise<ApiEmployeeSearchResponse> {
    try {
      const latestVersion = rootStore.domainDependencyStore.getLatestVersion(DataTypes.EMPLOYEE);
      if (!latestVersion) {
        throw new Error(`No latest version for EMPLOYEE in ${domain}`);
      }

      const employmentTemporality = presentOnly ? "('PRESENT')" : "('PAST', 'PRESENT')";
      const querySql = `
      with similarity_result as (
        select
            greatest(
                case when employee.employeeId = '${searchInput}' then 1 else 0 end,
                case when employee.companyEmail = '${searchInput}' then 1 else 0 end,
                coalesce(similarity(employee.fullName, '${searchInput}'), 0),
                coalesce(similarity(employee.localFullName, '${searchInput}'), 0),
                coalesce(similarity(employee.localFullNamePronunciation, '${searchInput}'), 0),
                coalesce(similarity(employee.preferredName, '${searchInput}'), 0),
                coalesce(similarity(employee.localPreferredName, '${searchInput}'), 0),
                coalesce(similarity(employee.localPreferredNamePronunciation, '${searchInput}'), 0)
            ) as similarity,
          employee.employeeId,
          employee.companyEmail,
          employee.firstName,
          employee.lastName,
          employee.fullName,
          employee.localFullName,
          employee.localFirstName,
          employee.localLastName,
          employee.preferredName,
          employee.localPreferredName,
          employee.jobTitle
        from employee
        where
          employee.versionId = '${latestVersion}'
          and employee.employmentTemporality in ${employmentTemporality}
          and employee.namespace = '${domain}'
        order by
          similarity desc
    ) select
      employeeId,
      companyEmail,
      firstName,
      lastName,
      fullName,
      localFullName,
      localFirstName,
      localLastName,
      preferredName,
      localPreferredName,
      jobTitle,
      similarity
    from similarity_result
    where similarity > 0.1
    limit 15;
  `;

      const responseItems = (
        await rootStore.graphQlRequestService.graphQlSdk.executeWhitelistedSqlQuery({
          domain,
          querySql,
          selectedExecutorRole: rootStore.permissionsStore.getExecutorRole().id,
          simulateRole: rootStore.permissionsStore.getSimulateRoleId(),
        })
      ).executeWhitelistedSqlQuery?.data.map((rows) => {
        const valueRow = rows.map((field) => {
          if (field) {
            const value = parseCustomSqlValue(field);
            if (!value) {
              throw new Error(`Unexpected employee search query response shape for ${rows} and ${field}`);
            } else {
              return value;
            }
          } else {
            return null;
          }
        });

        const nameMapped: ApiEmployeeSearchResponseItem = {
          employeeId: valueRow[0] as string,
          companyEmail: valueRow[1] as string,
          firstName: valueRow[2] as string,
          lastName: valueRow[3] as string,
          fullName: valueRow[4] as string,

          localFullName: valueRow[5] as string,
          localFirstName: valueRow[6] as string,
          localLastName: valueRow[7] as string,
          preferredName: valueRow[8] as string,
          localPreferredName: valueRow[9] as string,
          jobTitle: valueRow[10] as string,
        };

        return nameMapped;
      });

      if (responseItems) {
        return {
          employees: responseItems,
        };
      } else {
        throw new Error('employee search response from backend was undefined');
      }
    } catch (E) {
      console.error(`Cannot run employee search in domain ${domain}. Returning nothing. Reason: ${E}`, E);
      trackAPIError(E);
      return {
        employees: [],
      };
    }
  }

  private async getReportingLineWithoutCache(
    domain: Domains,
    versionId: string,
    managerId?: string,
    onlyManagers = true
  ): Promise<ApiGetManagerResponse> {
    try {
      const querySql = getReportingLineSql({ managerId, onlyManagers, domain, versionId });
      const responseItems = (
        await rootStore.graphQlRequestService.graphQlSdk.executeWhitelistedSqlQuery({
          domain,
          querySql,
          selectedExecutorRole: rootStore.permissionsStore.getExecutorRole().id,
          simulateRole: rootStore.permissionsStore.getSimulateRoleId(),
        })
      ).executeWhitelistedSqlQuery?.data.map((rows) => {
        const valueRow = rows.map((field) => {
          if (field) {
            const value = parseCustomSqlValue(field);
            if (value === null || value === undefined) {
              throw new Error(`Unexpected getReportingLine query response shape for ${rows} and ${field}`);
            } else {
              return value;
            }
          } else {
            return null;
          }
        });
        const f: ApiGetManagerResponseSubordinateItem = {
          id: valueRow[0] as string,
          fullname: valueRow[1] as string,
          managerId: valueRow[2] as string,
          managerOrIc: valueRow[3] as string,
          hierarchyLevel: valueRow[4] as number,
          path: valueRow[5] as string,
          subordinatesCount: valueRow[6] as number,
        };

        return f;
      });

      if (responseItems) {
        const hierarchyByRoot = groupBy(responseItems, (row) => {
          return row.path.split('.').first() ?? '';
        });

        const resultlist: ApiGetManagerHierarchy[] = [];
        for (const rootId in hierarchyByRoot) {
          const subordinates = hierarchyByRoot[rootId];
          const root = subordinates.find((subordinate) => subordinate.id === rootId);
          if (root) {
            const entry = {
              subordinates: subordinates.filter((subordinate) => subordinate.id !== rootId),
              ...root,
            };
            resultlist.push(entry);
          } else {
            throw new Error('Did not find the root in the subordinates. This is a bug');
          }
        }
        return { items: resultlist };
      } else {
        throw new Error('getReportingLine response from backend was undefined');
      }
    } catch (E) {
      console.error(`Cannot run getReportingLine in domain ${domain}. Reason: ${E}`, E);
      trackAPIError(E);
      throw E;
    }
  }

  public async getReportingLine(
    domain: Domains,
    versionId: string,
    managerId?: string,
    onlyManagers = true
  ): Promise<ApiGetManagerResponse> {
    const cacheKey = JSON.stringify(['getReportingLine', domain, managerId, onlyManagers, versionId]);
    return this.cache.getFromCacheOrRequest<ApiGetManagerResponse>(cacheKey, () =>
      this.getReportingLineWithoutCache(domain, versionId, managerId, onlyManagers)
    );
  }

  public async getDirectReports(
    domain: string,
    managerId: string,
    onlyManagers = true
  ): Promise<ApiGetManagerHierarchy> {
    const latestVersion = rootStore.domainDependencyStore.getLatestVersion(DataTypes.EMPLOYEE);
    if (!latestVersion) {
      throw new Error(`No latest version for EMPLOYEE in ${domain}`);
    }

    const result = await this.getReportingLine(domain as Domains, latestVersion, managerId, onlyManagers);
    const managerResult = result.items.first();
    if (managerResult) {
      return managerResult;
    } else {
      throw new Error(`Could not find direct reports for ${managerId} ${domain}`);
    }
  }
}

export const employeeDataApiService = new EmployeeDataApiService(appCache);
